import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import './addressSection.css';
import { styled, css } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import AddressDetails from './EditAddress/AddressDetails';
import AddressForm from './AddAddress/AddressForm';
import { icons } from '../../Assets/icons';

interface AddressSectionProps {
  customerData: any;
  getCustomerData:any;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function cancel() {}
function cont() {}

const AddressSection: React.FC<AddressSectionProps> = ({ customerData,getCustomerData }) => {
  const [addressData, setAddressData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleUpdateAddressList = () => {

  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    getCustomerData();
  };

  const handleClickEditOpen = (address: any) => {
    setAddressData(address);
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Box className="address-section">
      <Box className="header">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="appt"
        >
          {/* <AddressDetails  onCancel={handleClose} onContinue={cont}  /> */}
          <AddressForm onCancel={handleClose} onContinue={cont} />
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleEditClose}
          aria-labelledby="customized-dialog-title"
          open={openEdit}
          className="appt"
        >
          <AddressDetails
            addressData={addressData}
            onCancel={handleEditClose}
            onContinue={cont}
          />
        </BootstrapDialog>

        <Typography variant="h4" component="h3" className="title">
          My Addresses
        </Typography>
        <Button
          className="add-button"
          onClick={handleClickOpen}
          startIcon={
            <img
              loading="lazy"
              src={icons.profilePlusAddress}
              alt="Add New Address"
              className="icon"
            />
          }
        >
          Add New Address
        </Button>
      </Box>
      {customerData.address.map((row: any, index: number) => (
        <div key={index}>
          <Typography variant="h6" className="subtitle">
            {row.address_type == 1
              ? 'Home'
              : row.address_type == 2
                ? 'Work'
                : 'Other'}
          </Typography>
          <Typography variant="body1" className="address-text">
            {`${row.address_line_1} ${row.address_line_2} ${row.city_name} ${row.state_name} ${row.country_name},  ${row.postal_code}  `}{' '}
            <img
              alt="edit-icon"
              src={icons.edit}
              onClick={() => {
                handleClickEditOpen(row);
              }}
            />
          </Typography>
        </div>
      ))}
    </Box>
  );
};

export default AddressSection;
