import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import AppointmentSection from './AppointmentSection/AppointmentSection';
import AppointmentDetails from './AppointmentDetails/AppointmentDetails';
import UserProfile from './UserProfile';
import './AppointmentPage.css';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import { ShopAPI } from '../../../src/hooks/shop';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { icons } from '../../../src/Assets/icons';
import toast from 'react-hot-toast';
const AppointmentPage: React.FC = () => {
  const { getAppointmentListVendorAPICall } = ShopAPI()
  const [appointmentData, setAppointmentData] = useState<any>(null)
  const [appointmentDetails, setAppointmentDetails] = useState<any>({})
  const getAppointmentList = async () => {
    if (customerData && customerData?.customer_id) {
      const result = await getAppointmentListVendorAPICall(customerData.customer_id);
      if (result?.data) {
        setAppointmentData(result?.data)
      } else {
        toast.error("Error while fetching appointment list")
      }
    }
  }
  const [customerData, setCustomerData] = useState<any>(null);
  useEffect(() => {
    let customerInfo = localStorage.getItem('customer_details');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
      setCustomerData(customerInfo);
      getAppointmentList();
    }
  }, []);
  useEffect(() => {
    if (customerData) {
      getAppointmentList();
    }
  }, [customerData]);
  const updateAppointment = (data: any) => {
    setAppointmentDetails(data)
  }
  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue); // Ensure newValue is a number
  };
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const handleAppointmentSelect = (appointment: any) => {
    setSelectedAppointment(appointment);
  };
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  const isBlank = (obj: any) => Object.keys(obj).length === 0;
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  let appointment_id: string | undefined;
  if (isBlank(appointmentDetails) == true) {
    if (appointmentData) {
      if (appointmentData.upcoming.length > 0) {
        appointment_id = String(appointmentData.upcoming[0]?.id);
      } else {
        appointment_id = String(appointmentData.latest[0]?.id);
      }
    }
  } else {
    appointment_id = String(appointmentDetails.id);
  }
  return (
    <Box
      className="appointment-page"
      sx={{ pb: 14, bgcolor: 'white', overflow: 'hidden' }}
    >
      <Container
        component="main"
        sx={{ mt: 3, maxWidth: { md: '1550px', xs: '100%' } }}
      >
        <Grid container spacing={5} direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 5, md: 0 } }}>
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              Appointment
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                className='tabs'
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    <span>
                      Upcoming <span className='count-chip upcoming'>
                        {appointmentData?.upcoming?.length || 0}
                      </span>
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <span>
                      Past <span className='count-chip past'>
                        {appointmentData?.past?.length || 0}
                      </span>
                    </span>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                {appointmentData?.upcoming?.length > 0 ? (
                  <AppointmentSection
                    title="Upcoming"
                    count={appointmentData.upcoming.length}
                    data={appointmentData.upcoming}
                    updateAppointment={updateAppointment}
                  />
                ) : (
                  <Box className="noAppointment" alignItems="center" gap={2.5} mt={4}>
                    <center>
                      <img src={icons.noAppointment} />
                      <Typography variant="h1">
                        No upcoming appointments
                      </Typography>
                      <Typography variant="body2">
                        Make some appointments for schedule data to appear
                      </Typography>
                      <Button className='searchSalon'>Search Saloon</Button>
                    </center>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {appointmentData && appointmentData?.past?.length > 0 ? (
                  <AppointmentSection
                    title="Past"
                    count={appointmentData?.past?.length}
                    data={appointmentData?.past}
                    isPast
                    updateAppointment={updateAppointment}
                  />
                ) : (
                  <Box className="noAppointment" alignItems="center" gap={2.5} mt={4}>
                    <center>
                      <img src={icons.noAppointment} />
                      <Typography variant="h1">
                        No past appointments
                      </Typography>
                      <Typography variant="body2">
                        You have no past appointments
                      </Typography>
                    </center>
                  </Box>
                )}
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} sx={{ mt: { xs: 10, md: 0 } }}>
            <Grid container spacing={5} direction={{ xs: 'column', md: 'row' }}>
              <Grid item xs={12} md={9}>
                <AppointmentDetails
                  image={appointmentDetails?.cover_photo}
                  business={appointmentDetails.business}
                  date={appointmentDetails.appointment_date}
                  appointment_date={appointmentDetails.appointment_date}
                  address={"N/A"}
                  location={appointmentDetails?.address?.address_line_1}
                  lattitude={appointmentDetails?.address?.lattitude}
                  longitude={appointmentDetails?.address?.longitude}
                  address_line_2={appointmentDetails?.address?.address_line_2}
                  city={appointmentDetails?.address?.city_name}
                  country={appointmentDetails?.address?.country_name}
                  service={"N/A"}
                  services={"N/A"}
                  service_name={"N/A"}
                  currency={"₹"}
                  status={appointmentDetails?.status}
                  processTime={appointmentDetails?.total_hours}
                  price={appointmentDetails?.total_amount}
                  priceIcon={appointmentDetails?.currency?.currency_icon}
                  total_services={appointmentDetails?.total_services}
                  bookingRef={""}
                  customerId={customerData?.customer_id}
                  vendorDetailId={appointmentDetails.vendor_detail_id}
                  appointment_id={appointment_id || ""}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default AppointmentPage;