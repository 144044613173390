import React, {useState, useRef} from 'react';
import {
    Box,
    Typography,
    Avatar,
    Button,
    Divider,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    TextField,
} from '@mui/material';
import {images} from '../../Assets/images';
import {icons} from '../../Assets/icons';
import {useCookies} from 'react-cookie';
import Dialog from '@mui/material/Dialog';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {styled, css} from '@mui/system';
import {Link, useNavigate} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import CommonCookie from '../CommonCookie';
import './profileSidebar.css';
import {CustomerAPI} from '../../../src/hooks/customer';
import toast from 'react-hot-toast';
import moment from "moment";


interface ProfileSidebarProps {
    customerData: any;
    getCustomerData: any;
}


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const ProfileSidebar: React.FC<ProfileSidebarProps> = ({customerData, getCustomerData}) => {
    const {updateCustomerProfileAPICall} = CustomerAPI()
    const navigate = useNavigate();
    const [cookies, , removeCookie] = useCookies([
        CommonCookie.token,
        CommonCookie.user,
        CommonCookie.userType,
        CommonCookie.profile,
        CommonCookie.vendor_id,
    ]);
    const [file, setFile] = useState({} as any);
    const [fileURL, setFileURL] = useState('');

    function Logout() {
        removeCookie('token', {path: '/'});
        navigate('/');
    }

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setFile(file);
            setFileURL(imageUrl);
            // Perform any action with the selected file, such as uploading
        }
    };

    const [addressData, setAddressData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickEditOpen = (address: any) => {
        setAddressData(address);
        setOpenEdit(true);
    };
    const handleEditClose = () => {
        setOpenEdit(false);
    };

    const [customerUpdateData, setCustomerUpdateData] = useState<any>(customerData)


    const updateProfielDataFunc = async (customerUpdateData: any) => {
        var data = new FormData();
        data.append('email', customerUpdateData.email);
        data.append('first_name', customerUpdateData.first_name);
        data.append('last_name', customerUpdateData.last_name);
        data.append('profile_image', file);
        data.append('DOB', moment(customerUpdateData.DOB).format('YYYY-MM-DD'));
        data.append('anniversary_date', customerUpdateData.anniversary_date);
        data.append('gender_type', customerUpdateData.gender_type);
        data.append('country_code', JSON.parse(localStorage.getItem('customer_details') || '91').country_code);
        data.append('country_iso', JSON.parse(localStorage.getItem('customer_details') || 'in').country_iso);
        data.append('mobile', customerUpdateData.mobile);
        try {
            const result = await updateCustomerProfileAPICall(data);
            if (!result) {
                toast.error("Error while update profile data")
            }
            if (result?.status_code === 200) {
                toast.success("Profile data updated successfully")
                getCustomerData();
                handleClose();
            } else {
                toast.error(result?.message || 'Failed to update profile data');
            }
        } catch (error) {
            console.error(error);
            toast.error("Error while update profile data")
        }
// }
    }


    const getInitials = (name: string) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        let initials =
            nameParts.length > 1
                ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
                : nameParts[0][0]; // Use only the first initial if there's one word
        return initials.toUpperCase();
    };

    return (
        <Box className="profile-sidebar">
            <Box className="profile-image-container">
                <BootstrapDialog
                    onClose={handleEditClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    className="editProfile"
                >
                    <Box className="header-box">
                        <Typography variant="h4" component="h1">
                            Edit Profile
                        </Typography>
                        <img
                            loading="lazy"
                            src={icons.cancel}
                            onClick={handleClose}
                            className="address-image"
                            alt="Edit Address"
                        />
                    </Box>

                    {customerData.profile_image ? (
                        <Avatar
                            alt={`${customerData.first_name} ${customerData.last_name}'s profile picture`}
                            src={fileURL || customerData.profile_image} // Corrected to use the profile_image dynamically
                            className="profile-avatar"
                        />
                    ) : (
                        <Avatar className="profile-avatar">
                            {getInitials(`${customerData.first_name} ${customerData.last_name}`)}
                        </Avatar>
                    )}
                    <div>
                        <button onClick={handleClick} className="camera-upload-button">
                            <img alt="Upload" src={icons.camera} className="profile-edit-icon"/>
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{display: "none"}} // Hide the input element
                            onChange={handleFileChange}
                        />
                    </div>


                    <Grid container spacing={2} sx={{padding: '0.5rem 2rem'}}>
                        <Grid item xs={6}>
                            <TextField
                                placeholder="First Name"
                                className="input-field firstName"
                                fullWidth
                                value={customerUpdateData.first_name}
                                //  onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, first_name: event.target.value }) }}
                                onChange={(event: any) => {
                                    setCustomerUpdateData({...customerUpdateData, first_name: event.target.value})
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                placeholder="Last Name"
                                className="input-field lastName"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={customerUpdateData.last_name}
                                onChange={(event: any) => {
                                    setCustomerUpdateData({...customerUpdateData, last_name: event.target.value})
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{padding: '0rem 2rem'}}>
                        <Grid item xs={6}>
                            <TextField
                                placeholder="Moble Number"
                                className="input-field mobileNumber"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={customerUpdateData.mobile}
                                onChange={(event: any) => {
                                    setCustomerUpdateData({...customerUpdateData, mobile: event.target.value})
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                placeholder="Email Address"
                                className="input-field email"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={customerUpdateData.email}
                                onChange={(event: any) => {
                                    setCustomerUpdateData({...customerUpdateData, email: event.target.value})
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{padding: '0.5rem 2rem'}}>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                className='gender'
                            >
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    value={customerUpdateData.gender_type}
                                    onChange={(event: any) => {
                                        setCustomerUpdateData({...customerUpdateData, gender_type: event.target.value})
                                    }}
                                >
                                    <MenuItem key={1} value={1}>
                                        Male
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                        Female
                                    </MenuItem>
                                    <MenuItem key={3} value={3}>
                                        Other
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Date of Birth"
                                        value={customerUpdateData.DOB ? dayjs(customerUpdateData.DOB) : null} // Convert to Dayjs
                                        onChange={(newValue) => {
                                            setCustomerUpdateData({
                                                ...customerUpdateData,
                                                DOB: newValue ? newValue.format('YYYY-MM-DD') : '', // Format as needed
                                            });
                                        }}
                                        className="input-field birthdate"
                                    />
                                </DemoContainer>
                            </LocalizationProvider>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{padding: '0.2rem 2rem'}}>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Anniversary Date"
                                        value={customerUpdateData.anniversary_date ? dayjs(customerUpdateData.anniversary_date) : null} // Convert to Dayjs
                                        onChange={(newValue) => {
                                            setCustomerUpdateData({
                                                ...customerUpdateData,
                                                anniversary_date: newValue ? newValue.format('YYYY-MM-DD') : '', // Format as needed
                                            });
                                        }}
                                        className="input-field birthdate anniversaryDate"
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button
                        className="continue"
                        variant="contained"
                        onClick={() => {
                            updateProfielDataFunc(customerUpdateData)
                        }}
                    >
                        Update Profile
                    </Button>
                </BootstrapDialog>
                {customerData.profile_image ? (
                    <Avatar
                        alt={`${customerData.first_name} ${customerData.last_name}'s profile picture`}
                        src={customerData.profile_image} // Corrected to use the profile_image dynamically
                        className="profile-avatar"
                    />
                ) : (
                    <Avatar className="profile-avatar">
                        {getInitials(`${customerData.first_name} ${customerData.last_name}`)}
                    </Avatar>
                )}
                <Link to="#">
                    <img alt="" src={icons.camera} className="profile-edit-icon"/>
                </Link>
            </Box>
            <Typography variant="h4" className="profile-name">
                {`${customerData.first_name} ${' '} ${customerData.last_name}`}
            </Typography>
            <Button onClick={handleClickOpen} className="edit-info-btn">Edit basic info</Button>
            <Box className="profile-details">
                <Divider/>
                <Typography className="profile-label">First Name</Typography>
                <Typography className="profile-value">
                    {customerData.first_name}
                </Typography>

                <Typography className="profile-label">Last Name</Typography>
                <Typography className="profile-value">
                    {customerData.last_name}
                </Typography>

                <Typography className="profile-label">Mobile Number</Typography>
                <Typography className="profile-value">{customerData.mobile}</Typography>

                <Typography className="profile-label">Email Address</Typography>
                <Typography className="profile-value">{customerData.email}</Typography>

                {customerData.DOB && (
                    <>
                        <Typography className="profile-label">Date of Birth</Typography>
                        <Typography className="profile-value">
                            {customerData.DOB}
                        </Typography>
                    </>
                )}
                {customerData.gender_type && (
                    <>
                        <Typography className="profile-label">Gender</Typography>
                        <Typography className="profile-value">
                            {customerData.gender_type === "1" && "Male"}
                            {customerData.gender_type === "2" && "Female"}
                            {customerData.gender_type === "3" && "Other"}
                        </Typography>
                    </>
                )}
                {customerData.anniversary_date && (
                <>
                    <Typography className="profile-label">Anniversary date</Typography>
                    <Typography className="profile-value">{customerData.anniversary_date}</Typography>
                </>
                )}
            </Box>
            
            <Divider className="divider-bottom"/>
            <Button className="logout-btn" onClick={Logout}>
                <span>Log Out</span>
                <img alt="logout" src={icons.logout} className="logout-icon"/>
            </Button>
        </Box>
    );
};

export default ProfileSidebar;
