import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { icons } from '../../Assets/icons';
import { useNavigate } from 'react-router-dom';
import './SearchItems.css';

type SearchItems = {
  photo: string;
  vendor_detail_id: number,
  title: string;
  rating: string;
  ratingTotal: string;
  salonType: string;
  location: string;
};

const SearchItems: React.FC<SearchItems> = ({
  photo,
  vendor_detail_id,
  title,
  rating,
  ratingTotal,
  salonType,
  location,
}) => {

  const navigate = useNavigate();

  function gotoShop() {
    try {
      localStorage.setItem('shopID', vendor_detail_id.toString());
      navigate('/shop');
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  }

  return (
    <Card className="appointment-card">
      <Box className="card-container">
        <Box className="image-container">
          <img
            loading="lazy"
            src={photo}
            alt={title}
            className="appointment-image"
          />
        </Box>
        <CardContent className="content-container">
          <Grid container spacing={2} className="grid-container">
            <Grid item xs={9} md={9} >
              <Typography variant="h6" className="title">
                {title}
              </Typography>
              <Typography variant="body2" className="price">
                {salonType === '1' ? (
                  "Male Salon"
                ) : salonType === '2' ? (
                  "Female Salon"
                ) : (
                  "Unisex Salon"
                )}
              </Typography>
              <Typography
  variant="body2"
  className="location"
  style={{
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
  }}
>
  {location && ( 
  <img
    loading="lazy"
    src={icons.yellowLocation}
    className="location-icon"
    alt="Location pin icon"
    style={{ verticalAlign: 'middle', marginRight: '8px' }}
  />
  )}
  {location}
</Typography>
            </Grid>
            <Grid item xs={3} md={3} >
              <Typography variant="body1" className="rating-text">
                {rating} <img
                  loading="lazy"
                  src={icons.rating}
                  alt="Rating star icon"
                  className="rating-star-icon"
                />
              </Typography>
              <Typography variant="body2" className="next-icon">
                <img onClick={gotoShop} src={icons.blackRightArrow} />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
};

export default SearchItems;
