// src/hooks/useApi.ts
import {
  getCountryList,
  getStateList,
  getCityList,
  getDropdownListOptions,
  getCategoryList,
} from '../../src/api/commonAPI';
import {
  ApiResponse,
  CategoryListResponse,
  CityList,
  CountryList,
  DropdownListResponse,
  StateList,
} from '../../src/api/types/common';
export const CommonAPI = () => {
  const getCountryListAPICall = async (): Promise<ApiResponse<CountryList>> => {
    try {
      const response: ApiResponse<CountryList> = await getCountryList();
      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse<CountryList>; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: [], // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse<CountryList>; // Cast to CityListResponses
    }
  };

  const getStateListAPICall = async (
    country_id: number,
  ): Promise<ApiResponse<StateList>> => {
    try {
      const response: ApiResponse<StateList> = await getStateList(country_id);
      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse<StateList>; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: [], // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse<StateList>; // Cast to CityListResponses
    }
  };

  const getCityListAPICall = async (
    stateId: number,
    countryId: number,
  ): Promise<ApiResponse<CityList>> => {
    try {
      const response: ApiResponse<CityList> = await getCityList(
        stateId,
        countryId,
      );
      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse<CityList>; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: [], // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse<CityList>; // Cast to CityListResponses
    }
  };

  const getDropdownListOptionsAPICall =
    async (): Promise<DropdownListResponse> => {
      try {
        const response: DropdownListResponse = await getDropdownListOptions();
        return {
          data: response.data, // Ensure this matches City[]
          message: response.message,
          status_code: response.status_code,
          success: response.success,
        } as DropdownListResponse; // Cast to CityListResponses
      } catch (err: any) {
        return {
          data: {}, // Ensure this matches City[]
          message: err.toString(),
          status_code: 500,
          success: false,
        } as DropdownListResponse; // Cast to CityListResponses
      }
    };

  const getCategoryListAPICall = async (
    categoryType: number,
  ): Promise<CategoryListResponse> => {
    try {
      const response: CategoryListResponse =
        await getCategoryList(categoryType);
      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as CategoryListResponse; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: {}, // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as CategoryListResponse; // Cast to CityListResponses
    }
  };

  return {
    getCountryListAPICall,
    getStateListAPICall,
    getCityListAPICall,
    getDropdownListOptionsAPICall,
    getCategoryListAPICall,
  };
};
