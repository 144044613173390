import React, { createContext, useState, ReactNode, useRef, Dispatch, SetStateAction } from 'react';
import {
  ApiResponse,
  CityList,
  CategoryList,
  DropdownListResponse,
} from '../../src/api/types/common';
import {
  BannerResponse,
  DashboardResponse,
  getVendorListingReqInterface,
} from '../../src/api/types/dashboard';
import { CommonAPI } from '../../src/hooks/common';
import { DashboardAPI } from '../../src/hooks/dashboard';

interface AppContextProps {
  getCityListFunc: (
    stateId: number,
    countryId: number,
  ) => Promise<ApiResponse<CityList> | null>;
  getCategoryListFunc: (type: number) => Promise<ApiResponse<CategoryList>>;
  getBannerImagesFunc: () => Promise<BannerResponse>;
  getDropdownListFunc: () => Promise<DropdownListResponse>;
  getDashboardDataFunc: () => Promise<DashboardResponse>;
  getVenorListingFunc: (data: getVendorListingReqInterface) => Promise<DashboardResponse>;
  someGlobalState: string;
  setSomeGlobalState: Dispatch<SetStateAction<string>>;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [someGlobalState, setSomeGlobalState] = useState('default value');

  const {
    getBannerImagesAPICall,
    getDashboardDataAPICall,
    getVendorListingAPICall,
  } = DashboardAPI();
  const {
    getCityListAPICall,
    getDropdownListOptionsAPICall,
    getCategoryListAPICall,
  } = CommonAPI();

  const getCityListFuncRef = useRef(
    async (stateId: number, countryId: number): Promise<ApiResponse<CityList> | null> => {
      return await getCityListAPICall(stateId, countryId);
    }
  );

  const getCategoryListFuncRef = useRef(
    async (type: number): Promise<ApiResponse<CategoryList>> => {
      return await getCategoryListAPICall(type);
    }
  );

  const getBannerImagesFuncRef = useRef(async (): Promise<BannerResponse> => {
    return await getBannerImagesAPICall();
  });

  const getDropdownListFuncRef = useRef(async (): Promise<DropdownListResponse> => {
    return await getDropdownListOptionsAPICall();
  });

  const getDashboardDataFuncRef = useRef(async (): Promise<DashboardResponse> => {
    return await getDashboardDataAPICall();
  });

  const getVenorListingFuncRef = useRef(
    async (data: getVendorListingReqInterface): Promise<DashboardResponse> => {
      return await getVendorListingAPICall(data);
    }
  );

  return (
    <AppContext.Provider
      value={{
        getCityListFunc: getCityListFuncRef.current,
        getCategoryListFunc: getCategoryListFuncRef.current,
        getBannerImagesFunc: getBannerImagesFuncRef.current,
        getDropdownListFunc: getDropdownListFuncRef.current,
        getDashboardDataFunc: getDashboardDataFuncRef.current,
        getVenorListingFunc: getVenorListingFuncRef.current,
        someGlobalState,
        setSomeGlobalState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
