import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const BrandLogo: React.FC = () => {
  return (
    <Box
      display="flex"
      position="relative"
      alignItems="flex-start"
      mb={{ xs: 2.5, md: 0 }}
      maxWidth={{ xs: '100%' }}
      fontSize={{ xs: '2.5rem', md: 'initial' }}
    >
      <Box
        display="flex"
        gap={0}
        alignSelf="stretch"
        padding={2.5}
        minWidth={240}
        maxWidth={{ xs: '100%' }}
        fontSize={{ xs: '2.5rem', md: 'initial' }}
      >
        <Typography variant="h4" fontWeight={50}>
          Glam
        </Typography>
        <Typography variant="h4" fontWeight="bold">
          9
        </Typography>
      </Box>
    </Box>
  );
};

export default BrandLogo;
