import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Box, Grid, FormControl, MenuItem, Select, Typography, Button, InputLabel } from "@mui/material";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import "./Map.css"; // Importing the external CSS for styling
import MuiSliderMin from '@mui/material/Slider';
import { getVendorListingReqInterface } from '../../../src/api/types/dashboard';
import { useNavigate } from 'react-router-dom';
import useHomeContext from '../../../src/hooks/useHomeContext';
import toast from 'react-hot-toast';
import { images } from '../../Assets/images';
import useAppContext from '../../../src/hooks/useAppContext';
import { ExitStatus } from 'typescript';

interface CategoryList {
  id: number;
  name: string;
  logo_link: string;
  sequence: number;
  is_premium_category: number;
}

interface MapProps {
  itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}

interface MapData {
  vendor_list: { data: any }[];
}


export const Map: React.FC<MapProps> = ({ itemData }) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };
  //	const [mapData, setMapData] = useState('');
  const [mapData, setMapData] = useState<MapData | null>(null);
  const gotoShop = (id: string) => {
    try {
      localStorage.setItem('shopID', id.toString());
      navigate('/shop', { replace: true });
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  };
  const {
    getVenorListingFunc,
  } = useAppContext();

  const [dashboardDataReq, setDashboardDataReq] =
    useState<getVendorListingReqInterface>({
      search_keyword: '',
      sort_by: 0,
      salon_type: 0,
      category_id: 0,
      professional_keyword: '',
      gender_type: 0,
      service_percentage: '',
      latitude: 0,
      longitude: 0
    });

  const mapContainerStyle = {
    width: '100%',
    height: '900px',
  };

  const [sortBy, setSortBy] = useState("");
  const [nearBy, setNearBy] = useState("");
  const handleChange = (event: any) => {
    setSortBy(event.target.value); // Update state when an option is selected
  };

  const handleNearBy = (event: any) => {
    setNearBy(event.target.value); // Update state when an option is selected
  };

  const MAX = 25000;
  const MIN = 0;
  const minMarks = [
    {
      value: MIN,
      label: '',
    },
    {
      value: MAX,
      label: '',
    },
  ];
  const [data, setData] = useState<CategoryList[]>([]);
  const [minValue, setMinValue] = useState(MIN); // Separate state for min slider
  const [maxValue, setMaxValue] = useState(MAX);
  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + '/get-categories?use_for=2';

  const vendorListingFilter22 = useCallback(
    async (data: getVendorListingReqInterface) => {
      try {
        const updatedData = {
          ...data,
          category_id: selectedCategory || 0,
          gender_type: sortBy ? parseInt(sortBy) : 0, // Gender filter
        latitude: nearBy === "3" ? 23.0248008 : 0, // Example latitude for 'Nearest' option
        longitude: nearBy === "3" ? 72.4762654 : 0, // Example longitude for 'Nearest' option
        service_percentage: `${minValue}-${maxValue}`, // Price range filter
        };
  
        const result: any = await getVenorListingFunc(updatedData);
        if (result === null) {
          toast.error('Failed to filter data.');
          return;
        }
        if (result?.status_code === 200 && result?.data) {
          const dataObject = {
            vendor_list: [{ data: result?.data?.data }],
          };
          if (JSON.stringify(mapData) !== JSON.stringify(dataObject)) {
            setMapData(dataObject); // Update only if data is new
          }
        } else {
          toast.error(result?.message);
        }
      } catch (error) {
        console.error('Error filtering vendor list:', error);
      }
    },
    [getVenorListingFunc, selectedCategory, mapData]
  );


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiBaseUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Run this only once on mount

  const handleMinChange = (event: any, newValue: any) => {
    if (newValue <= maxValue) { // Ensure minValue doesn't exceed maxValue
      setMinValue(newValue);

    }
  };

  const handleMaxChange = (event: any, newValue: any) => {
    if (newValue >= minValue) { // Ensure maxValue doesn't go below minValue
      setMaxValue(newValue);
    }
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  
  return (
    <main>
      {process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={13}
            center={{ lat: 23.0248008, lng: 72.4762654 }}
          >
            {(mapData && mapData.vendor_list && mapData.vendor_list.length > 0 ? mapData.vendor_list[0].data : itemData).map((marker: any, index: number) => (
  <Marker
    key={index}
    position={{
      lat: parseFloat(marker.vendor_address?.lattitude),
      lng: parseFloat(marker.vendor_address?.longitude),
    }}
    label={{
      text: marker.business_name,
      color: "black",
      fontSize: '10px',
      fontWeight: "normal",className: 'marker-label'
    }}
    onClick={() => {
      setSelectedMarker(marker);
      gotoShop(marker.vendor_detail_id);
    }}
  />
))}
          </GoogleMap>
        </LoadScript>
      ) : (
        <Typography variant="body1" color="textSecondary">
          Unable to load the map. Please check the location details.
        </Typography>
      )}
    </main>
  );
};
