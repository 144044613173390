// src/contexts/HomeContext.tsx
import React, { createContext, useRef, ReactNode } from 'react';
// Interface
import {
  ApiResponse,
  CityList,
  CategoryList,
  DropdownListResponse,
} from '../../src/api/types/common';
import {
  BannerResponse,
  DashboardResponse,
  getVendorListingReqInterface,
} from '../../src/api/types/dashboard';
// API
import { CommonAPI } from '../../src/hooks/common';
import { DashboardAPI } from '../../src/hooks/dashboard';
interface DataContextType {
  getCityListFunc: (
    stateId: number,
    countryId: number,
  ) => Promise<ApiResponse<CityList> | null>;
  getCategoryListFunc: (type: number) => Promise<ApiResponse<CategoryList>>;
  getBannerImagesFunc: () => Promise<BannerResponse>;
  getDropdownListFunc: () => Promise<DropdownListResponse>;
  getDashboardDataFunc: (
  ) => Promise<DashboardResponse>;
  getVenorListingFunc: (
    data: getVendorListingReqInterface,
  ) => Promise<DashboardResponse>;
}
export const HomeContext = createContext<DataContextType | undefined>(
  undefined,
);
const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {
    getBannerImagesAPICall,
    getDashboardDataAPICall,
    getVendorListingAPICall,
  } = DashboardAPI();
  const {
    getCityListAPICall,
    getDropdownListOptionsAPICall,
    getCategoryListAPICall,
  } = CommonAPI();

  // City List
  const getCityListFuncRef = useRef(
    async (
      stateId: number,
      coutnryId: number,
    ): Promise<ApiResponse<CityList> | null> => {
      const result: ApiResponse<CityList> = await getCityListAPICall(
        stateId,
        coutnryId,
      );
      return result;
    },
  );
  // category list
  const getCategoryListFuncRef = useRef(
    async (type: number): Promise<ApiResponse<CategoryList>> => {
      const result: ApiResponse<CategoryList> =
        await getCategoryListAPICall(type);
      return result;
    },
  );
  // Banner Images
  const getBannerImagesFuncRef = useRef(async (): Promise<BannerResponse> => {
    const result: BannerResponse = await getBannerImagesAPICall();
    return result;
  });
  // Dropdown List
  const getDropdownListFuncRef = useRef(
    async (): Promise<DropdownListResponse> => {
      const result: DropdownListResponse =
        await getDropdownListOptionsAPICall();
      return result;
    },
  );
  // Dashboard Data
  const getDashboardDataFuncRef = useRef(
    async (
    ): Promise<DashboardResponse> => {
      const result: DashboardResponse = await getDashboardDataAPICall(

      );
      return result;
    },
  );
  // Vendor Listing Data
  const getVenorListingFuncRef = useRef(
    async (data: getVendorListingReqInterface): Promise<DashboardResponse> => {
      const result: DashboardResponse = await getVendorListingAPICall(data);
      return result;
    },
  );
  return (
    <HomeContext.Provider
      value={{
        getCityListFunc: getCityListFuncRef.current,
        getBannerImagesFunc: getBannerImagesFuncRef.current,
        getDropdownListFunc: getDropdownListFuncRef.current,
        getDashboardDataFunc: getDashboardDataFuncRef.current,
        getCategoryListFunc: getCategoryListFuncRef.current,
        getVenorListingFunc: getVenorListingFuncRef.current,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
export default DataProvider;
