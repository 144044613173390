import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Avatar,
  Grid,
} from '@mui/material';
import './AppointmentDetails.css';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Dialog from '@mui/material/Dialog';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled, css } from '@mui/system';
import AppointmentManager from '../AppointmentManager';
import ManageAppointmentCard from '../ManageAppointmentCard';
import AppointmentAction from '../AppointmentAction';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { isConstructorDeclaration } from 'typescript';

interface services {
  service_name: string;
}

type Address = {
  address_line_2?: string;
};

type Currency = {
  currency_icon?: string;
};

type Data = {
  address?: Address;
  service?: services;
  currency: Currency;
}

interface AppointmentData {
  cover_photo?: string;
  business: string;
  appointment_date: string;
  date: string;
  image: string;
  address: string;
  location: string;
  lattitude: string;
  longitude: string;
  address_line_2: string;
  city: string;
  country: string;
  service: string;
  services: string;
  currency: string;
  service_name: string;
  status: string;
  processTime: string;
  price: string;
  total_services: string;
  bookingRef: string;
  customerId: string;
  appointment_id: string;
  vendorDetailId: string;
  priceIcon: string;
}

// const data: Data | null = null;

type AppointmentDetailsProps = {
};

const AppointmentDetails: React.FC<AppointmentData> = ({
  cover_photo,
  business,
  appointment_date,
  date,
  image,
  location,
  lattitude,
  longitude,
  address,
  address_line_2,
  city,
  country,
  service,
  services,
  service_name,
  status,
  processTime,
  price,
  currency,
  priceIcon,
  total_services,
  bookingRef,
  customerId,
  vendorDetailId,
  appointment_id,

}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const [addressData, setAddressData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  //  const [data, setData] = React.useState<any[]>([])
  //const [data, setData] = useState<any[] | undefined>(undefined);
  const [data, setData] = useState<AppointmentData | null>(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen1 = () => {
    setOpen(false);
    setOpenEdit(true);
  };
  const handleClose1 = () => {
    setOpenEdit(false);
  };
  const navigate = useNavigate();
  const gotShop = () => {
    localStorage.setItem('shopID', vendorDetailId);
    navigate('/shop');
  };
  const customer_id = customerId;
  //const appointment_id = appointmentID;
  const handleLocationClick = (lat: any, lng: any, name: any) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}(${encodeURIComponent(name)})`;
    window.open(url, '_blank');
  };
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + '/get-appointment-detail';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = `Bearer ${localStorage.getItem('customer_auth_token')}` || "";
        const response = await fetch(apiBaseUrl, {
          method: 'POST', // Assuming POST is required
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: JSON.stringify({ customer_id, appointment_id }), // Pass shopID in the request body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result.data); // Assuming setData updates your state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [appointment_id]); // Runs only once on mount
  const cancelAppointment = async () => {
    const CancelApptUrl = `${process.env.REACT_APP_API_BASE_URL}/cancel-appointment`;
    try {
      const response = await fetch(CancelApptUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appointment_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      toast.error('Your Appointment is successfully Cancelled');
      handleClose1();
    } catch (error) {
      toast.error('Error cancelling appointment:');
    }
  }

  

  return data  ?  (
    <Card className="appointment-card right">
      <CardMedia
        component="img"
        image={data?.cover_photo || images.noImageBlack}
        alt={data?.business}
        className="appointment-image"
      />
      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
        className="ClosedAppt"
      >
        <Box className="appointment-manager">
          <Box component="main" className="appointment-main">
            <Card className="appointment-section">
              <Box component="header" className="appointment-header">
                <Avatar
                  alt="Appointment Icon"
                  src={icons.close}
                  className="appointment-avatar"
                  onClick={handleClose1}
                />
              </Box>
              <center><h2>Are You Sure You Want To Cancel?</h2>
                <h3>If You Want To Change The Appointment, You Can Reschedule Your Appointment.
                </h3></center>
              <Box className="yesCancel">
                <Button variant="contained" className="" onClick={cancelAppointment}>
                  Yes, Cancel
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="manageAppt"
      >
        <Box className="appointment-manager">
          <Box component="main" className="appointment-main">
            <Card className="appointment-section">
              <Box component="header" className="appointment-header">
                <Typography variant="h4" className="appointment-title">
                  Manage Appointment
                </Typography>
                <Avatar
                  alt="Appointment Icon"
                  src={icons.close}
                  className="appointment-avatar"
                  onClick={handleClose}
                />
              </Box>
              <ManageAppointmentCard
                imageSrc={image}
                title={data?.business}
                date={data?.appointment_date || 'Sat, 14 Sept, 2024 at 10:00 am'}
                location={location + ", " + address_line_2 + ", " + city + " " + country + " - " + total_services + " Items"}
              />
              <Box className="appointment-action-container">
                <Button variant="contained" className="appointment-action-button">
                  Reschedule Appointment
                </Button>
                <Divider className="appointment-action-divider" />
              </Box>
              <Box className="appointment-action-container" onClick={handleClickOpen1} >
                <Button variant="contained" className="appointment-action-button">
                  Cancel Appointment
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </BootstrapDialog>
      <CardContent className="appointment-content">
        <Grid container className="grid-container">
          <Grid item xl={6} xs={12} md={6}>
            <Box>
              <Typography
                variant="h4"
                className="appointment-title"
                sx={{ marginTop: '30px' }}
              >
                {data?.business || 'Beauty Spot Richmond'}
              </Typography>
              <Typography
                variant="h6"
                className="appointment-date"
                sx={{ marginTop: '10px' }}
              >
                {data?.appointment_date || 'Sat, 14 Sept, 2024 at 10:00 am'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={5.5} xs={12} md={6}>
            <Box
              className={
                data?.status === '4' ? "status-container Completed" :
                  data?.status === '3' ? "status-container Cancelled" :
                    data?.status === '2' ? "status-container Ongoing" :
                      data?.status === '1' ? "status-container Pending" :
                        "status-container Pending"
              }
              sx={{ marginTop: '20px', marginBottom: '20px', float: 'right' }}
            >
              {data?.status === '3' && (
                <img
                  loading="lazy"
                  src={icons.rightTick}
                  alt="Confirmed"
                  className="status-icon"
                />
              )}
              <Typography variant="body1">{
                data?.status === '4' ? "Completed" :
                  data?.status === '3' ? "Cancelled" :
                    data?.status === '2' ? "Ongoing" :
                      data?.status === '1' ? "Pending" :
                        "Pending"
              } </Typography>
            </Box>
          </Grid>
        </Grid>

        {data?.status === '4' && (
          <Box className="manage-appointment-container" onClick={gotShop}>
            <img
              loading="lazy"
              src={icons.upcomingCalender}
              alt="Manage Appointment"
              className="manage-appointment-icon"
            />
            <Box>
              <Typography variant="h6">Book Again</Typography>
              <Typography variant="body2">Book Your Next Appointment</Typography>
            </Box>
          </Box>
        )}

        {data?.status === '4' && (
          <>
            <Divider className="divider" />
            <Box className="getting-there-container">
              <img
                loading="lazy"
                src={icons.upcomingLocation}
                alt="Getting There"
                className="getting-there-icon"
              />
              <Box onClick={() => handleLocationClick(lattitude, longitude, data.business)} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <Typography variant="h6">Getting There</Typography>
                <Typography variant="body2">{data?.business}</Typography>
              </Box>
            </Box>
          </>
        )}
        {data?.status !== '4' ? (
          <Box
            className="manage-appointment-container"
            onClick={handleClickOpen}
            sx={{ marginTop: '10px', marginBottom: '10px', cursor: 'pointer' }}
          >

            <Box className="manage-appointment-container" onClick={handleClickOpen}>
              <img
                loading="lazy"
                src={icons.upcomingManage}
                alt="Manage Appointment"
                className="manage-appointment-icon"
              />
              <Box>
                <Typography variant="h6">Manage Appointment</Typography>
                <Typography variant="body2">Reschedule or Cancel</Typography>
              </Box>
            </Box>
          </Box>

        ) : (
          <></>
        )}
        <Divider className="divider" />
        <Box onClick={gotShop}
          className="venue-details-container"
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <img
            loading="lazy"
            src={icons.upcomingVenue}
            alt="Venue Details"
            className="venue-details-icon"
          />
          <Box>
            <Typography variant="h6">Venue Details</Typography>
            <Typography variant="body2">{(data?.address as any)?.address_line_1 + ", " + (data?.address as any)?.address_line_2 + ", " + (data?.address as any)?.city_name + ", " + (data?.address as any)?.country_name}</Typography>
          </Box>
        </Box>
        <Divider className="divider" />
        {status === '4' && (
          <Box onClick={gotShop}
            className="venue-details-container"
            sx={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <img
              loading="lazy"
              src={icons.appointmentReview}
              alt="Venue Details"
              className="venue-details-icon"
            />
            <Box>
              <Typography variant="h6">Review</Typography>
              <Typography variant="body2">Comment Your Experience</Typography>
            </Box>
          </Box>
        )}
        <Box className="overview-container">
          <Typography variant="h5">Overview</Typography>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={7}>
              <Typography variant="body1">
                {(data?.services[0] as any)?.service_name}
              </Typography>
              <Typography variant="body2">
                Process Time: {(data?.services[0] as any)?.total_hours}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="h5"
                sx={{ textAlign: 'right' }}
                className="price"
              >
                {(data?.currency as any)?.currency_icon}{(data?.services[0] as any)?.service_amount}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h6">Total</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="h5"
                sx={{ textAlign: 'right' }}
                className="price"
              >
                {(data?.currency as any)?.currency_icon}{(data?.services[0] as any)?.service_amount}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="h6"
          className="cancellation-policy"
          sx={{ marginTop: '10px' }}
        >
          Cancellation Policy
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          Please avoid cancelling within{' '}
          <span className="highlight">30 minutes</span> of your appointment
          time.
        </Typography>
        {bookingRef && (
          <Typography variant="h6" sx={{ marginTop: '10px', }}>
            Booking Reference: {bookingRef}
          </Typography>
        )}
      </CardContent>
    </Card>
  ):(
    <p className='appointmentNotFound'></p>
  )
};
export default AppointmentDetails;