import {
  getDashboardData,
  getBannerImages,
  getVendorListing,
} from '../../src/api/dashboardAPI';
import {
  DashboardResponse,
  BannerResponse,
  getVendorListingReqInterface,
} from '../../src/api/types/dashboard';
export const DashboardAPI = () => {
  const getDashboardDataAPICall = async (

  ): Promise<DashboardResponse> => {
    try {
      const response: DashboardResponse = await getDashboardData();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as DashboardResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as DashboardResponse;
    }
  };

  const getBannerImagesAPICall = async (): Promise<BannerResponse> => {
    try {
      const response: any = await getBannerImages();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as BannerResponse;
    } catch (err: any) {
      return {
        data: [],
        message: err.toString(),
        status_code: 500,
        success: false,
      } as BannerResponse;
    }
  };

  const getVendorListingAPICall = async (
    data: getVendorListingReqInterface,
  ): Promise<DashboardResponse> => {
    try {
      const response: DashboardResponse = await getVendorListing(data);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as DashboardResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as DashboardResponse;
    }
  };

  return {
    getDashboardDataAPICall,
    getBannerImagesAPICall,
    getVendorListingAPICall,
  };
};
