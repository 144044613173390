import React, { useState } from 'react'; // Import useState
import { Box, Typography, Divider, Button, Grid } from '@mui/material';
// Interface
import { Service } from '../../../../src/api/types/vendor_services';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import './BookingSummary.css';
import { VendorDetailData } from '../../../../src/api/types/shop';
import moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
// Props
interface SummaryItemProps {
  name: string;
  price: number;
  processTime: string;
}
interface summaryProps {
  data: Service[];
  nextPage: Function;
  vendorData?: VendorDetailData;
  appointmentDate?: string;
  appointmentTime?: string;
  totalHours?: number;
  isSlotAvailable: Boolean;
  bookPage: number;
}

// Item
const SummaryItem: React.FC<SummaryItemProps> = ({
  name,
  price,
  processTime,
}) => (
  <Grid container spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
    <Grid item xs={8}>
      <Typography variant="body1" fontWeight="medium">
        {name}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        Process Time: {processTime}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body1" fontWeight="bold" align="right">
        ₹{price}
      </Typography>
    </Grid>
  </Grid>
);

const Summary: React.FC<summaryProps> = ({
  vendorData,
  appointmentDate,
  appointmentTime,
  data,
  nextPage,
  totalHours,
  isSlotAvailable,
  bookPage,
}) => {
  const navigate = useNavigate();
  function handleClose() {
    setopenConfirm(false);
  }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const total = data.reduce(
    (sum: any, service: any) => sum + parseInt(service.charges),
    0,
  );
  const [openConfirm, setopenConfirm] = useState(false);

  function formatTime(hours: number, minutes: number) {
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes
      ? minutes.toString().padStart(2, '0')
      : '00';
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  function createTimeSlot(startTime: string, totalHours: number) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    let currentHour = startHours;
    let currentMinute = startMinutes;

    currentHour += totalHours;
    const start = formatTime(startHours, startMinutes);
    const end = formatTime(currentHour, currentMinute);

    return `${start} - ${end} (Duration: ${totalHours} hour${totalHours > 1 ? 's' : ''})`;
  }
  // Example usage
  const letStartTime = appointmentTime ?? '00:00';
  const letTotalHours = totalHours ?? 1;
  const timeSlots = createTimeSlot(letStartTime, letTotalHours);
  const genderType = vendorData?.vendor_detail?.data?.gender_type;

  return (
    <Box className="booking-summary">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirm}
        className="conf"
      >
        <center className="confirmed">
          <div className="image">
            <img alt="rightTickImage" src={icons.rightTick}></img>
          </div>
          <h2>Appointment Confirmed!</h2>
          <p>
            Lorem Ipsum is simply dummy text of the<br></br> printing and
            typesetting industry.{' '}
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{
              textTransform: 'capitalize',
              marginTop: '15px',
              padding: '15px 150px',
            }}
            className="btn btn-primary"
          >
            Close
          </Button>
        </center>
      </BootstrapDialog>
      {vendorData && vendorData?.media_images && (
        <Box className="salon-info">
          <img
            loading="lazy"
            src={
              vendorData?.cover_photo
                ? vendorData.cover_photo
                : vendorData?.media_images?.[0]?.media_name
                  ? vendorData.media_images[0].media_name
                  : images.noImageBlack // Use default image if media_name is undefined or not found
            }
            alt={vendorData?.vendor_detail?.title || 'Salon Image'}
            className="salon-image"
          />

          <Box className="salon-details">
            <Box className="salon-header">
              <Typography variant="subtitle2" className="salon-name">
                {vendorData?.vendor_detail.data.business_name}
              </Typography>
              <Box className="salon-rating">
                <Typography variant="subtitle2">
                  {vendorData?.vendor_detail.data.rating_value} (
                  {vendorData?.vendor_detail.data.total_review})
                </Typography>
                <img
                  loading="lazy"
                  src={icons.rating}
                  alt=""
                  className="rating-icon"
                />
              </Box>
            </Box>
            <Typography variant="body2" className="salon-type">
            {vendorData?.vendor_detail?.data?.gender_type === '1' ? (
        "Male"
      ) : vendorData?.vendor_detail?.data?.gender_type === '2' ? (
        "Female"
      ) : (
        "Unisex"
      )}
            </Typography>
            <Box className="salon-location">
              <img
                loading="lazy"
                src={icons.yellowLocation}
                alt=""
                className="location-icon"
              />
              <Typography variant="body2">
                {vendorData?.vendor_detail.data.address}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {appointmentDate && appointmentTime && (
        <Box className="date-time">
          <Box className="date">
            <img
              loading="lazy"
              src={icons.calender}
              alt=""
              className="date-icon"
            />
            <Typography variant="body2">
              {moment(appointmentDate).format('dddd D, MMMM')}
            </Typography>
          </Box>
          <Box className="time">
            <img loading="lazy" src={icons.time} alt="" className="time-icon" />
            <Typography variant="body2">{timeSlots}</Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          p: { xs: 2, md: 2 },
          mx: 'auto',
          width: '100%',
          maxWidth: 600,
          textAlign: 'left',
          bgcolor: 'white',
          borderRadius: 3,
          border: 1,
          borderColor: 'grey.800',
          marginTop: '20px',
        }}
        className="summary"
      >
        <Typography variant="h5" fontWeight="bold">
          Summary
        </Typography>
        <Divider sx={{ my: 2 }} />
        {data && data.length > 0 ? (
          <>
            {data.map((service, index) => (
              <SummaryItem
                key={index}
                name={service.service_name}
                processTime={service.service_time}
                price={parseInt(service.charges)}
              />
            ))}
          </>
        ) : (
          <Typography variant="body1" fontWeight="bold">
            No Services Selected
          </Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            ₹{total}
          </Typography>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            nextPage();
          }}
          sx={{
            mt: 5,
            py: 2,
            px: { xs: 3, md: 8 },
            textTransform: 'capitalize',
            borderRadius: '12px',
          }}
          fullWidth
          disabled={!isSlotAvailable ? true : data.length > 0 ? false : true}
        >
          {bookPage >= 5 ? 'Book Appointment' : 'Continue'}
        </Button>
      </Box>
    </Box>
  );
};
export default Summary;
