import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppProvider } from './contexts/AppContext';
import theme from './styles/theme';
import App from './App';
import {store} from "./redux/store"
import {Provider} from 'react-redux'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppProvider>
        <App />
        </AppProvider>
      </ThemeProvider>
    </Provider>,
);
