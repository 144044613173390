import React, {useState, useEffect, useMemo, useCallback, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {
    Box,
    Typography,
    Checkbox,
    Button,
    TextField,
    FormControlLabel,
    FormControl,
    FormHelperText,
    MenuItem, Select,
    InputLabel,
    Grid,
} from '@mui/material';
import BrandLogo from '../login/brandLogo/brandLogo';
import PhoneInput from '../login/phoneInput/phoneInput';
import AuthService from '../../Services/auth.service';
import {useNavigate, Link} from 'react-router-dom';
import BeautySpot from './BeautySpot';
import SearchItems from './SearchItems';
import {Map} from './Map';
import toast from 'react-hot-toast';
import {useCookies} from 'react-cookie';
import CommonCookie from '../../Components/CommonCookie';
import {images} from '../../../src/Assets/images';
import {icons} from '../../Assets/icons';
import {getVendorListingReqInterface} from '../../../src/api/types/dashboard';
import useHomeContext from '../../../src/hooks/useHomeContext';
import useAppContext from '../../../src/hooks/useAppContext';
import './Search.css';

/* Map Import  */

import "./Map.css"; // Importing the external CSS for styling
import Slider from '@mui/material/Slider';
import {ConstructionOutlined} from '@mui/icons-material';

interface CategoryList {
    id: number;
    name: string;
    logo_link: string;
    sequence: number;
    is_premium_category: number;
}

interface MapProps {
    itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}

interface MapData {
    vendor_list: { data: any }[];
}

/* Map Import  */

const Search = () => {
    const authService = AuthService();
    const navigate = useNavigate();

    const {
        getCityListFunc,
        getBannerImagesFunc,
        getDropdownListFunc,
        getDashboardDataFunc,
        getCategoryListFunc,
        getVenorListingFunc,
    } = useAppContext();

    const [dashboardData, setDashboardData] = useState<any>(null);
    const [mapData, setMapData] = useState<MapData | null>(null);
    const location = useLocation();
    const [data, setData] = useState(null);
    const searchReq = location.state?.searchReq;
    const filterURL = process.env.REACT_APP_API_BASE_URL + '/get-vendor-listing';
    const searchData = location.state?.data;


    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (searchReq) {
            fetchSearchResults(searchReq);
        } else {
         //   toast.error('No search criteria provided.');
        }
    }, [searchReq]);

    //console.log('on search page',searchData.data);

    const fetchSearchResults = async (data: getVendorListingReqInterface) => {
        const result: any = await getVenorListingFunc(data);
        if (result === null) {
            toast.error('Failed to filter data.');
            return;
        }
        if (result?.status_code === 200 && result?.data) {
            let dataObject: any = {
                vendor_list: [
                    {
                        title: `${result?.data?.total}`,
                        data: result?.data?.data
                    }
                ],
            };
            setDashboardData(dataObject);
        } else {
            let dataObject: any = {
                vendor_list: [
                    {
                        title: `No Records Found`,
                        data: []
                    }
                ],
            };
            setDashboardData(dataObject);
            toast.error(result?.message);
        }
    };

    const total = (mapData?.vendor_list[0]?.data ? mapData?.vendor_list[0]?.data.length : dashboardData?.vendor_list[0].data?.length || 0);
    const pageSize = 5; // Number of items per page
    const totalPages = Math.ceil(total / pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    function backToHome() {
        navigate('/');
    }

    function gotoShop() {
        try {
            localStorage.setItem('shopID', dashboardData?.vendor_list[0]?.data[0]?.vendor_media?.vendor_detail_id.toString());
            navigate('/shop');
        } catch (error) {
            console.error('Error navigating to shop:', error);
        }
    }
    const paginatedData = (mapData?.vendor_list[0]?.data || dashboardData?.vendor_list[0]?.data)?.slice(
        1 // Skip the first item
    ).slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );
    console.log('paginated',paginatedData)

   /* if(searchReq){
    const paginatedData = (mapData?.vendor_list[0]?.data || dashboardData?.vendor_list[0]?.data)?.slice(
        1 // Skip the first item
    ).slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );
    }else{
    const paginatedData = (searchData.data)?.slice(
        1 // Skip the first item
    ).slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );
} */

console.log('sss',paginatedData);
    /* const paginatedData = dashboardData?.vendor_list[0].data?.slice(
      1 // Skip the first item
    ).slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    ); */


    /* const paginatedData = useMemo(() => {
    if (!mapData || !mapData.vendor_list || !mapData.vendor_list[0]) {
      return dashboardData?.vendor_list[0].data?.slice(
        1 // Skip the first item
      ).slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );
    }
    return mapData?.vendor_list[0].data?.slice(
      1 // Skip the first item
    ).slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [mapData, dashboardData, currentPage, pageSize]); */


    const memoizedItemData = useMemo(() => {
        if (!dashboardData || !dashboardData.vendor_list || !dashboardData.vendor_list[0]) {
            return [];
        }
        //return dashboardData.vendor_list[0].data ||
        return mapData?.vendor_list[0]?.data || dashboardData?.vendor_list[0]?.data
    }, [dashboardData, mapData]);


    /* Map Code */
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const handleCategoryChange = (event: any) => {
        setSelectedCategory(event.target.value);
    };
    //	const [mapData, setMapData] = useState('');

    /* const gotoShop = (id: string) => {
      try {
        localStorage.setItem('shopID', id.toString());
        navigate('/shop', { replace: true });
      } catch (error) {
        console.error('Error navigating to shop:', error);
      }
    };
    const {
      getVenorListingFunc,
    } = useAppContext();
  */
    const [dashboardDataReq, setDashboardDataReq] =
        useState<getVendorListingReqInterface>({
            search_keyword: '',
            sort_by: 0,
            salon_type: 0,
            category_id: 0,
            professional_keyword: '',
            gender_type: 0,
            service_percentage: '',
            latitude: 0,
            longitude: 0
        });

    const mapContainerStyle = {
        width: '100%',
        height: '900px',
    };

    const [sortBy, setSortBy] = useState("");
    const [nearBy, setNearBy] = useState("");


    const handleGenderChange = (event: any) => {
        setSortBy(event.target.value); // Update state when an option is selected
        console.log(event.target.value);

    };

    const clearFilters = () => {
        setSortBy("");
        setNearBy("");
        setSelectedCategory(null);
        setPriceRange([0, 25000]);
    };

    /* function valuetext(value: number) {
      return `${value}`;
    } */
    const handleNearBy = (event: any) => {
        setNearBy(event.target.value); // Update state when an option is selected
    };

    const [value, setValue] = useState([0, 25000]);
    const [priceRange, setPriceRange] = useState<number[]>([0, 25000]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
    };
    const valuetext = (value: any) => `₹${value}`;
    const MAX = 25000;
    const MIN = 0;
    const minMarks = [
        {
            value: 0,
            label: '₹0',
        },
        {
            value: 25000,
            label: '₹25000',
        },
    ];
    const [catdata, setCatData] = useState<CategoryList[]>([]);
    const [categoryData, setCategoryData] = useState<CategoryList[]>([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + '/get-categories?use_for=2';
    const vendorListingFilter22 = useCallback(
        async (data: getVendorListingReqInterface) => {
            try {
                const updatedData = {
                    ...data,
                    category_id: selectedCategory || 0,
                    gender_type: sortBy ? parseInt(sortBy) : 0, // Gender filter
                    latitude: nearBy === "3" ? 23.0248008 : 0, // Example latitude for 'Nearest' option
                    longitude: nearBy === "3" ? 72.4762654 : 0, // Example longitude for 'Nearest' option
                    //  service_percentage: `${priceRange[0]}-${priceRange[1]}`, // Price range filter
                };

                const result: any = await getVenorListingFunc(updatedData);
                if (result === null) {
                    toast.error('Failed to filter data.');
                    return;
                }
                if (result?.status_code === 200 && result?.data) {
                    const dataObject = {
                        vendor_list: [{data: result?.data?.data}],
                    };
                    if (JSON.stringify(mapData) !== JSON.stringify(dataObject)) {
                        setMapData(dataObject); // Update only if data is new

                    }
                } else {
                    toast.error(result?.message);
                }
            } catch (error) {
                console.error('Error filtering vendor list:', error);
            }
        },
        [getVenorListingFunc, selectedCategory, mapData, sortBy]
    );


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiBaseUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setCatData(result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Run this only once on mount


    const [selectedMarker, setSelectedMarker] = useState(null);

    /* Map Code */

    return (
        <Box className="search">
            <Grid container spacing={2} className="grid-container">
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={1} md={1} sm={1} className='filterBack'>
                            <img onClick={backToHome} src={icons.back}/>
                        </Grid>
                        <Grid item xs={8} md={9} sm={1} className='filterText'>
                            We have
                            found {mapData?.vendor_list[0]?.data.length || dashboardData?.vendor_list[0].title} search
                            results for <span style={{color: '#0178FA'}}>salon</span><br></br>
                            around your location
                        </Grid>
                        <Grid item xs={3} md={2} className='filterButton'>
                            <Button className='mapFilter'>Filters <img src={icons.filter}/></Button>
                        </Grid>
                    </Grid>

                    <BeautySpot

                        photo={mapData?.vendor_list[0]?.data[0]?.vendor_media?.media_name || dashboardData?.vendor_list[0]?.data[0]?.vendor_media?.media_name || images.noImageBlack}
                        name={mapData?.vendor_list[0]?.data[0]?.business_name ? mapData?.vendor_list[0]?.data[0]?.business_name : dashboardData?.vendor_list[0]?.data[0]?.business_name || ""}
                        rating={dashboardData?.vendor_list[0]?.data[0]?.rating || 0}
                        gender={mapData?.vendor_list[0]?.data[0]?.gender ? mapData?.vendor_list[0]?.data[0]?.gender : dashboardData?.vendor_list[0]?.data[0]?.gender || ""}
                        address={
                            (mapData?.vendor_list[0]?.data[0]?.vendor_address?.address_line_1 ? mapData?.vendor_list[0]?.data[0]?.vendor_address?.address_line_1 : dashboardData?.vendor_list[0]?.data[0]?.vendor_address?.address_line_1 || "Unspecified") +
                            (mapData?.vendor_list[0]?.data[0]?.vendor_address?.city ? mapData?.vendor_list[0]?.data[0]?.vendor_address?.city : dashboardData?.vendor_list[0]?.data[0]?.vendor_address?.city || "") +
                            (mapData?.vendor_list[0]?.data[0]?.vendor_address?.country ? mapData?.vendor_list[0]?.data[0]?.vendor_address?.country : dashboardData?.vendor_list[0]?.data[0]?.vendor_address?.country || "")
                        }
                        vendor_detail_id={dashboardData?.vendor_list[0]?.data[0]?.vendor_detail_id}
                    />

{paginatedData && paginatedData.length > 0 ? (
    paginatedData.map((vendor: any, index: any) => (
        <SearchItems 
            key={index}
            photo={vendor.vendor_media?.media_name || images.noImageBlack}
            vendor_detail_id={vendor.vendor_detail_id} 
            title={vendor.business_name}
            rating='4.9' 
            ratingTotal='126' 
            salonType={vendor.gender_type} 
            location={
                `${vendor.vendor_address?.address_line_1 || ""} ${vendor.vendor_address?.city || ""} ${vendor.vendor_address?.country || ""}`
            }
        />
    ))
) : (
    searchData?.data?.length > 0 ? (
        searchData.data.map((vendor: any, index: any) => (
            <SearchItems 
                key={index}
                photo={vendor.vendor_media?.media_name || images.noImageBlack}
                vendor_detail_id={vendor.vendor_detail_id} 
                title={vendor.business_name}
                rating='4.9' 
                ratingTotal='126' 
                salonType={vendor.gender_type} 
                location={
                    `${vendor.vendor_address?.address_line_1 || ""} ${vendor.vendor_address?.city || ""} ${vendor.vendor_address?.country || ""}`
                }
            />
        ))
    ) : (
        <div>No results found</div>
    )
)}
 



                    
                    {/* 
                    {paginatedData?.length > 0 && paginatedData.map((vendor: any, index: any) => (
                        <SearchItems photo={vendor.vendor_media.media_name || images.noImageBlack}
                                     vendor_detail_id={vendor.vendor_detail_id} title={vendor.business_name}
                                     rating='4.9' ratingTotal='126' salonType={vendor.gender_type} location={
                            (vendor.vendor_address.address_line_1 || "") +
                            (vendor.vendor_address.city || "") +
                            (vendor.vendor_address.country || "")
                        }/>
                    ))}
*/}



                    {(mapData?.vendor_list[0]?.data?.length > 5 || (!mapData && dashboardData?.vendor_list[0]?.data?.length > 5)) ? (

                        <Box display="flex" className="onlyBottom" justifyContent="flex-end" alignItems="center" mt={4}>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                sx={{marginRight: '8px'}}
                            >
                                Previous
                            </Button>
                            <Typography variant="body1" sx={{margin: '0 16px'}}>
                                Page {currentPage} of {totalPages}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </Button>
                        </Box>
                    ) : null}

                </Grid>
                <Grid item xs={12} md={8}>

                    <main>
                        <Grid container spacing={2} className="search-grid">

                        <Grid className='nearByFilter' item xs={12} md={2}>
                                <FormControl variant="outlined" fullWidth className='sortBylocation'>
                                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                                    <Select
                                        value={nearBy}
                                        onChange={handleNearBy}
                                        label="Near By"
                                        className='filterCategory'
                                        MenuProps={{
                                            disableScrollLock: true,
                                            PaperProps: {
                                                style: {maxHeight: 200,},
                                            },
                                        }}
                                    >
                                        <MenuItem value="1">Top Rated</MenuItem>
                                        <MenuItem value="3">Nearest</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>   

                            <Grid className='filterPrice' item xs={12} md={2}>
                                <>
                                    <Box>
                                        <Slider
                                            getAriaLabel={() => 'Price range'}
                                            value={priceRange}
                                            onChange={handleSliderChange}
                                            valueLabelDisplay="auto"
                                            marks={minMarks}
                                            min={0}
                                            max={25000}
                                            getAriaValueText={(value: any) => `${value}`}
                                        />
                                    </Box>
                                </>
                            </Grid>

                            <Grid className='genderFilter' item xs={12} md={2}>
                                <FormControl variant="outlined" fullWidth className='sortByGender'>
                                    <InputLabel id="demo-simple-select-label">Gender Type</InputLabel>
                                    <Select labelId="demo-simple-select-label"
                                            value={sortBy}
                                            onChange={handleGenderChange}
                                            label="Sort By"
                                    >
                                        <MenuItem value="1">Male</MenuItem>
                                        <MenuItem value='2'>Female</MenuItem>
                                        <MenuItem value='3'>Unisex</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid className='categoryFilter' item xs={12} md={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="category-select-label">Category</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        value={selectedCategory || ''}
                                        onChange={handleCategoryChange}
                                        className='filterCategory'
                                        MenuProps={{
                                            disableScrollLock: true,
                                            PaperProps: {
                                                style: {maxHeight: 200,},
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Select Category</em>
                                        </MenuItem>
                                        {catdata.length > 0 ? (
                                            catdata.map((row) => (
                                                <MenuItem key={row.id} value={row.id}>
                                                    {row.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No categories available</MenuItem> // Fallback UI when data is empty
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid className='searchFilter' item xs={12} md={2}>
                                <Button
                                    onClick={() =>
                                        vendorListingFilter22({
                                            ...dashboardDataReq,
                                            service_percentage: `${priceRange[0]}-${priceRange[1]}`, // Use updated price range directly
                                        })
                                    }
                                    className="mapSearch"
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid className='clearFilter' item xs={12} md={2}>
                                <Button
                                    onClick={() => clearFilters()
                                    }
                                    className="mapSearch"
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </main>
                    {dashboardData?.vendor_list?.[0]?.data ? (
                        <Map itemData={memoizedItemData}/>
                    ) : (
                        <div>Loading...</div>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
export default Search;
