import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './about.css';
import { icons } from '../../../Assets/icons';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
type AboutProps = {
  title: string;
  description: string;
  address: string;
  nearbyAreas: string;
  latLong: any;
};

const responsiveFontSizes = {
  xs: '0.8rem', // Smallest screen size (e.g., mobile)
  sm: '1rem', // Small screen size (e.g., tablet)
  md: '14px', // Medium screen size (e.g., desktop)
  lg: '16px', // Large screen size (e.g., widescreen)
  xl: '1.5rem', // Extra large screen size (e.g., very large monitor)
};
const mapContainerStyle = {
  width: '100%',
  height: '700px',
};

const About: React.FC<AboutProps> = ({
  title,
  description,
  address,
  nearbyAreas,
  latLong,
}) => {
  return (
    <Box className="about-section">
      <Grid container spacing={5} className="about-container">
        <Grid item xs={12} md={6}>
          <Box className="about-content">
            <Typography variant="h2" className="about-title">
              About
            </Typography>
            <Typography
              variant="h6"
              className="about-description"
              sx={{ fontSize: responsiveFontSizes }}
            >
                {description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
            </Typography>
            {address && (
            <Box className="about-address-container">
              <Typography variant="body1" className="about-address">
                {address?.replace(/,$/, "")}
              </Typography>
              <img
                loading="lazy"
                src={icons.shopLocation}
                alt=""
                className="about-icon"
              />
            </Box>
            )}
           {/* <Typography variant="body2" className="about-nearby">
              Nearby {nearbyAreas}
            </Typography> */}
          </Box>
        </Grid>
        <Grid className='about-map' item xs={12} md={6}>
        {latLong &&
  !isNaN(latLong.lat) &&
  !isNaN(latLong.lng) &&
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={latLong}
      >
        <Marker position={latLong} 
        label={{
          text: title,
          color: "black",
          fontSize: '10px',
          fontWeight: "normal",
          className: 'aboutMarkerLabel'
        }}
        />
        
      </GoogleMap>
    </LoadScript>
  ) : (
    <Typography variant="body1" color="textSecondary">
      Unable to load the map. Please check the location details.
    </Typography>
  )}
        </Grid>


        

      </Grid>
    </Box>
  );
};

export default About;
