import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/common/Header/Header';
import Footer from './Components/common/Footer/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Otp from './pages/Otp';
import Shop from './pages/Shop';
import Booking from './pages/Booking';
import SelectConfirm from './pages/Confirm';
import Review from './pages/Review';
import CreateAccount from './Components/register/creatAccount';
import AppointmentPage from './Components/appointment/AppointmentPage';
import ProfilePage from './Components/profile/ProfilePage';
import FavoritesPage from './Components/favorite/FavoritesPage';
import Reviews from './Components/reviews/ReviewPage';
import SearchPage from './Components/search/Search';
import { Toaster } from 'react-hot-toast';

const App: React.FC = () => (
  <Router>
    <Header />
    <div style={{ minHeight: '80vh' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* Shop Details */}
        <Route path="/shop" element={<Shop />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/confirm" element={<SelectConfirm />} />
        <Route path="/review" element={<Review />} />
        <Route path="/appointment" element={<AppointmentPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/favourite" element={<FavoritesPage />} />
        <Route path="/register" element={<CreateAccount />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/search" element={<SearchPage />} />
      </Routes>
    </div>
    <Footer />
    <Toaster />
  </Router>
);

export default App;
