import React, { useState, useEffect } from 'react';
import AppointmentCard from '../AppointmentCard/AppointmentCard';
import { Box, Typography, Button } from '@mui/material';
import './AppointmentSection.css';
import { images } from '../../../../src/Assets/images';

type AppointmentSectionProps = {
  title: string;
  count: number;
  data: any[];
  isPast?: boolean;
  updateAppointment: (appointmentData: any) => void;
};

const AppointmentSection: React.FC<AppointmentSectionProps> = ({
  title,
  count,
  data,
  isPast = false,
  updateAppointment,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of items per page

  const totalPages = Math.ceil((data?.length || 0) / pageSize);



  const paginatedData = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const handlePageChange = (newPage: number) => {
  //  if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
  //  }
  };

  const handleUpdateAppointment = (appointmentData: any) => {
    updateAppointment(appointmentData);
    // Do not reset currentPage
  };

  useEffect(() => {
    // If the new data length is less than the current page, reset to the last page
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [data, totalPages, currentPage]);

  return (
    <section className="top">
      {paginatedData && paginatedData.length > 0 ? (
        paginatedData.map((appointment, index) => {
          const latitude = appointment.address?.latitude || 0;
          const longitude = appointment.address?.longitude || 0;
          return (
            <AppointmentCard
              key={index}
              image={appointment.cover_photo || images.noImageBlack}
              title={appointment.business}
              lattitude={latitude}
              longitude={longitude}
              date={appointment.appointment_date}
              price={appointment.total_amount}
              itemCount={appointment.total_services}
              isPast={isPast}
              appointmentData={appointment}
              updateAppointment={() => handleUpdateAppointment(appointment)}
            />
          );
        })
      ) : (
        <p>No appointments available</p>
      )}
      {paginatedData && paginatedData.length > 0 && totalPages > 1 && (
        <Box
          display="flex"
          className="onlyBottom"
          justifyContent="flex-end"
          alignItems="center"
          mt={4}
        >
          <Button
            variant="contained"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ marginRight: '8px' }}
          >
            Previous
          </Button>
          <Typography variant="body1" sx={{ margin: '0 16px' }}>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="contained"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Box>
      )}
    </section>
  );
};

export default AppointmentSection;
