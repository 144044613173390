import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { icons } from "../../../src/Assets/icons";
import { useNavigate, Link } from 'react-router-dom';
interface BreadcrumbItem {
  label: string;
  isLast?: boolean;
}
interface BreadcrumbNavigationProps {
  items: BreadcrumbItem[];
}
export const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({
  items,
}) => {
  const navigate = useNavigate();
  function gotoShop(){
    localStorage.setItem('shopID', localStorage.getItem('shopID') ?? '');
      navigate('/shop');
  }
  function gotoHome(){
      navigate('/');
  }
  return (
    <Box
      component="nav"
      aria-label="Breadcrumb navigation"
      className="breadcrumb-container"
    >
      <img
        loading="lazy"
        src={icons.back}
        alt=""
        className="breadcrumb-logo"
      />
      <Box className="breadcrumb-items">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <Typography
              component="span"
              className={`breadcrumb-label ${
                item.isLast ? "breadcrumb-last" : ""
              }`}
            >
              {item.label === 'Home' ? (
  <Typography sx={{cursor:'pointer'}} onClick={gotoHome}>{item.label}</Typography>
) : item.label === 'All Reviews' ? (
  <Typography>{item.label}</Typography>
) : (
  <Typography sx={{cursor:'pointer'}} onClick={gotoShop}>{item.label}</Typography>
)}
            </Typography>
            {!item.isLast && <span className="breadcrumb-separator">&gt;</span>}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};