// src/api/userApi.ts

import {apiClient, apiClientForFiles} from './apiClient';
import { ApiResponseFromAPI } from './types/common';
import { CustomerAddressReqInterface } from './types/customer';

// Add Customer Address
export const addCustomerAddress = (data: CustomerAddressReqInterface) => {
  return apiClient<ApiResponseFromAPI>(`/store-customer-address`, {
    method: 'POST',
    body: data,
  });
};

// Get Customer Profile
export const getCustomerProfile = () => {
  return apiClient<ApiResponseFromAPI>(`/customer-profile`, { method: 'GET' });
};


// Update Customer Profile
export const updateCustomerProfile = (data: any) => {
  return apiClientForFiles<ApiResponseFromAPI>(`/update-customer-profile`, { method: 'POST', body: data});
};
