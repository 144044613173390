import React, {useState, useEffect} from 'react';
import {Box, Container, Grid, Typography, Divider} from '@mui/material';
import {useLocation, Link} from 'react-router-dom';
import {icons} from '../../../../src/Assets/icons';
import './Footer.css'

const Footer = (): JSX.Element => {
    const [show, setShow] = useState(false);
    const location = useLocation();
    useEffect(() => {
        setShow(false);
        let currentPage = location.pathname.toString();
        let notAllowedPages: string[] = ['/login', '/register', '/otp', '/search', '/reviews', '/appointment'];
        if (!notAllowedPages.some(page => currentPage.includes(page))) {
            setShow(true);
        }
    }, [location]);

    return (
        <>
            {show && (
                <Box
                    className="footerContainer"
                    sx={{
                        width: '100%',
                        backgroundColor: 'black',
                        color: 'white',
                        top: 0,
                    }}
                >
                    <Container className="footer" sx={{maxwidth: '100%'}}>
                        <Grid container spacing={4} sx={{padding: '20px 0'}}>
                            <Grid item xs={12} sm={3}>
                                {/*  <Typography
                  variant="h1"
                  sx={{
                    fontWeight: '100',
                  }}
                >
                  Glam
                  <Typography
                    component="span"
                    variant="h1"
                    sx={{
                      fontFamily: 'Poppins-ExtraBold, Helvetica',
                      fontWeight: '800',
                    }}
                  >
                    9
                  </Typography>
                </Typography> */}
                                <img className='footerIcon' src={icons.white} width="200px"/>
                                <Typography
                                    sx={{fontSize: "20px", marginTop: 1, color: 'white', fontWeight: "400"}}
                                >
                                    It is a long established fact that a reader will be distracted
                                    by the readable content of a page when looking at its layout.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins-SemiBold, Helvetica',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        color: "rgba(255, 255, 255, 1)"
                                    }}
                                >
                                    About Glam9
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Careers</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >

                                    <Link to="/">Customer Support</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Blog</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Sitemap</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins-SemiBold, Helvetica',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        color: "rgba(255, 255, 255, 1)"
                                    }}
                                >
                                    Glam9 Business
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">For Partner</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Pricing</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Support</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins-SemiBold, Helvetica',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        color: "rgba(255, 255, 255, 1)"
                                    }}
                                >
                                    Legal
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Privacy Policy</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Terms of Services</Link>
                                </Typography>
                                <Typography
                                    sx={{marginTop: 1, color: 'white', fontSize: "18px", fontWeight: "300"}}
                                >
                                    <Link to="/">Terms of Use</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins-SemiBold, Helvetica',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        color: "rgba(255, 255, 255, 1)"
                                    }}
                                >
                                    Our Social Media Profiles
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginTop: 2,
                                        gap: 2,
                                    }}
                                >
                                    <Link to={"/"}><img src={icons.fb}/></Link>
                                    <Link to={"/"}><img src={icons.instagram}/></Link>
                                    <Link to={"/"}><img src={icons.twitter}/></Link>
                                    <Link to={"/"}><img src={icons.linkedin}/></Link>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider sx={{backgroundColor: 'white', marginY: 2}}/>
                        <Typography
                            align="center"
                            sx={{
                                fontFamily: 'Poppins-Light, Helvetica',
                                fontWeight: '300',
                                paddingBottom: '20px',
                                fontSize:"18px",
                                color:"rgba(255, 255, 255, 1)"
                            }}
                        >
                            Copyright © 2024 glam9
                        </Typography>
                    </Container>
                </Box>
            )}
        </>
    );
};

export default Footer;
