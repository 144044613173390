import React from "react";
import { Box, Typography, Grid, Radio, BackdropRoot } from "@mui/material";
import './ScoreCard.css';
import Checkbox from '@mui/material/Checkbox';
import { string } from "prop-types";
interface ScoreCardProps {
  leftScore: number;
  rightScore: number;
  imageUrls: number;
  totalRating:string;
  onSelect: () => void;
  isSelected: boolean;
}

export function ScoreCard({ leftScore, rightScore, imageUrls,totalRating,isSelected,onSelect }: ScoreCardProps) {

const GradientHr = ({ value = 0 }: { value: number }) => {
  const sanitizedValue = Math.max(0, Math.min(100, value));
  const value2 = 100 - sanitizedValue;
  //console.log('val', parseFloat(value));
  const gradientStyle = {
   // background: 'linear-gradient(to right, black 66.666%, #e2e2e2 33.333%)',
    background: `linear-gradient(to right, black ${value}%, #e2e2e2 ${value}%)`,
    height: '2px',
    border: 'none',
  };

  return <hr className="custom-hr" style={gradientStyle} />;
};

  return (

    <Grid container spacing={2} alignItems="center" className="score-card" sx={{padding:'0'}}>
      {/* First Column - 10% */}
      <Grid item xs={1} sx={{padding:'0'}}>
      <Radio
          name="ratings"
          checked={isSelected} // Controlled state
          onChange={onSelect} // Handle changes
          sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
        />
      </Grid>
      
      {/* Second Column - 10% */}
      <Grid item xs={1} sx={{padding:'0'}}>
        <Box sx={{ color: 'white'  }}>
        <Box className="score-box">
        <Typography className="score-text">{leftScore}</Typography>
      </Box>
        </Box>
      </Grid>
      
      {/* Third Column - 70% */}
      <Grid item xs={7} sx={{padding:'0'}}>
      <Box className="image-container" >
      <GradientHr value={(rightScore / parseInt(totalRating)) * 100} />
      
        {/*<GradientHr value={Math.floor((rightScore / parseInt(totalRating)) * 100)} />
         <hr className="custom-hr" style={{ background: 'linear-gradient(to right, black 90%, #e2e2e2 10%)' }} />
         {imageUrls === 1 ? ( 
            <hr className="custom-hr" style={'background': 'linear-gradient(to right, black 90%, #e2e2e2 10%)'} />
        ):(
            <hr className="normal-hr" />  
        )}  */}    
      </Box>
      </Grid>
      
      {/* Fourth Column - 10% */}
      <Grid item xs={1} sx={{padding:'0'}}>
      <Box className="score-box">
        <Typography className="score-text">{rightScore}</Typography>
      </Box>
      </Grid>
    </Grid>

    
  );
}

export default function App() {
  return (
    <Grid container justifyContent="center" alignItems="center" className="app-container">
      <ScoreCard
  leftScore={10}
  rightScore={20}
  imageUrls={1}
  totalRating='5'
  onSelect={() => console.log('Checkbox changed')}
  isSelected={false}
/>
    </Grid>
  );
}
