import React from 'react';
import {
  Button,
  Typography,
  Box,
  TextField,
  Container,
  colors,
} from '@mui/material';
import PhoneInput from '../phoneInput/phoneInput';
import './loginForm.css';
import { useNavigate, Link } from 'react-router-dom';
import { icons } from '../../../Assets/icons';

interface LoginFormProps {
  addEmail: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailerror: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
  addEmail,
  email,
  setEmail,
  emailerror,
}) => {
  return (
    <Container className="login-form" maxWidth="sm">
      <Box className="topBar">
        <Link to="/">
          <img
            loading="lazy"
            src={icons.back}
            alt=""
            className="login-form-logo"
          />
        </Link>
        <h4 className="logoMobile">
          Glam<span>9</span>
        </h4>
      </Box>
      <Typography variant="h4" component="h1" className="login-form-title">
        Login
      </Typography>
      <Typography variant="body1" className="login-form-subtitle">
        Enter your phone number to login
      </Typography>
      <center>
        <PhoneInput email={email} setEmail={setEmail} emailerror={emailerror} />
        <Button
          variant="contained"
          color="primary"
          onClick={addEmail}
          fullWidth
          sx={{backgroundColor: '#0178FA'}}
          className="login-form-button"
        >
          Continue
        </Button>
      </center>
      <Typography variant="body2" className="login-form-footer">
        Have a business account? <br />
        Sign in as a professional
      </Typography>
      <Typography variant="body2" className="login-form-footer dontAccount">
        Don't have an account ? <Link to="/register">Sign Up</Link>
      </Typography>
    </Container>
  );
};

export default LoginForm;
