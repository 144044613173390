import React from 'react';
import { Box, Typography, Grid, Card, Avatar } from '@mui/material';
import CommonCookie from '../../CommonCookie';
import AppointmentOption from './AppointmentOption';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../Assets/icons';
import { Cookie } from '@mui/icons-material';
import { useCookies } from 'react-cookie';


interface AppointmentBookingProps {
  handleClose: () => void;
}


const AppointmentBooking: React.FC<AppointmentBookingProps> = ({
  handleClose,
}) => {
  const navigate = useNavigate();

  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  function gotoBooking() {
      if(cookies.token){
      navigate('/booking');
    }else{
      navigate('/login');
    }
  }

  function gotoGroupBooking() {
    navigate('/groupAppointment');
  }

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
        overflow: 'hidden',
      }}
    >
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: { xs: 2, md: 5 },
          py: { xs: 6, md: 85 },
          width: '100%',
          bgcolor: 'grey.800',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: { xs: 0, md: 5 },
            mb: { xs: 2, md: 0 },
            maxWidth: '100%',
            bgcolor: 'white',
            borderRadius: 3,
            boxShadow: 3,
            width: { xs: '100%', md: 705 },
          }}
        >
          <Grid
            container
            className="apptModal"
            spacing={3}
            sx={{ width: '100%' }}
          >
            <Grid item xs={12} md={11} sx={{paddingTop: '0px'}}>
              <Box className="top" sx={{ mt: { xs: 0, md: 14 }, textAlign: 'center' }}>
                <Typography variant="h4" component="h1" fontWeight="bold">
                  Choose an Option
                </Typography>
                <Typography variant="h6" component="h2" mt={2}>
                  Book an Appointment
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              className='close'
              xs={12}
              md={1}
              sx={{ textAlign: { xs: 'center', md: 'right' } }}
            >
              <Avatar
                alt="Appointment booking icon"
                src={icons.cancel}
                sx={{
                  width: 40,
                  height: 40,
                  mx: 'auto',
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>

          <AppointmentOption
            onClick={gotoBooking}
            title="Book an Appointment"
            description="Book an Appointment for Yourself"
          />
        {/*  <AppointmentOption
            onClick={gotoGroupBooking}
            title="Group Appointment"
            description="For Yourself and Others"
          /> */}
        </Card>
      </Box>
    </Box>
  );
};

export default AppointmentBooking;
