import React from "react";
import { Box, Typography, Rating } from "@mui/material";

/* interface TestimonialCardProps {
  name: string;
  date: string;
  content: string;
  imageSrc: string;
} */

  interface TestimonialCardProps {
    key: Number;
    id: Number;
    name: string;
    description: string;
    rating: string;
    createdDate: string
  }


export const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  id,
  description,
  rating,
  createdDate,
}) => {

  const formatDate = (dateString:any) => {
    const [day, month, year] = dateString.split("-");
    const date = new Date(`${year}-${month}-${day}`);
  
    // Add suffix for the day
    const daySuffix = (day:any) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
  
    const dayWithSuffix = parseInt(day) + daySuffix(parseInt(day));
    const monthName = date.toLocaleString("default", { month: "long" });
  
    return `${dayWithSuffix}, ${monthName} ${year}`;
  };

  return (
    <Box className="testimonial-card">
      <Box className="testimonial-content">
        <Box className="testimonial-header">
          <Typography variant="h6" className="testimonial-name">
            {name}
          </Typography>
          <Rating
  value={parseFloat(rating)}
  precision={0.5}
  readOnly
  sx={{ marginRight: 2, fontSize: '35px' }}
  max={5}
/>
        </Box>
        <Typography variant="body2" className="testimonial-date">
        {formatDate(createdDate)}
</Typography>
        <Typography variant="body1" className="testimonial-text">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
