import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import DateSelector from './DateSelector/DateSelector';
import TimeSelector from './TimeSelector/TimeSelector';
import './Time.css'; // Import the separate CSS file
import { appointmentObject } from '../../../src/api/types/appointment_slots';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
interface timeProps {
  appointmentData: appointmentObject[] | null;
  checkAppointmentAvaibilityAPICallFunc: Function;
}
const Time: React.FC<timeProps> = ({
  appointmentData,
  checkAppointmentAvaibilityAPICallFunc,
}) => {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [timeSlots, setTimeSlots] = useState<any>([]);
  // useEffect(() => {
  //   console.log("Came HERE");

  //   if (selectedDate && selectedTime) {
  //     checkAppointmentAvaibilityAPICallFunc(selectedDate, selectedTime);
  //   }
  // }, [selectedDate, selectedTime, checkAppointmentAvaibilityAPICallFunc]);

  useEffect(() => {
    if (appointmentData && appointmentData.length > 0) {
      setSelectedDate(appointmentData[0].date);
      setTimeSlots(appointmentData[0].slots);
    }
  }, [appointmentData]);
  console.log(timeSlots);
  return (
    <Grid item xs={12} md={9} className="team-selection">
      <Box
        className="appointment-booking"
        sx={{ pb: { xs: 3, md: 12 }, backgroundColor: 'white' }}
      >
        <Container className="bookingTime" maxWidth="lg">
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography variant="h4" className="time-selection-title">
                      Select Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <DateSelector
                    setSelectedDate={setSelectedDate}
                    setTimeSlots={setTimeSlots}
                    appointmentData={appointmentData}
                  />
                  {timeSlots && timeSlots.length > 0 ? (
                    <TimeSelector
                      setSelectedTime={setSelectedTime}
                      timeSlots={timeSlots}
                      selectedTime={selectedTime}
                      selectedDate={selectedDate}
                      checkAppointmentAvaibilityAPICallFunc={
                        checkAppointmentAvaibilityAPICallFunc
                      }
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Grid>
  );
};

export default Time;
