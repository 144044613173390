import { createSlice } from '@reduxjs/toolkit'
import {any} from "prop-types";

// Define a type for the User slice state
interface userState {
    details: any // User details
}

const initialState:userState = {
    details:null
}


export const userSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        setUserDetails: (state,action) => {
            state.details = action.payload
        },
        removeUserDetails:(state)=> {
            state.details = null
        }
    }
})

export const { setUserDetails,removeUserDetails} = userSlice.actions

export default userSlice.reducer