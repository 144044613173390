// Assuming images is an object where keys are strings and values are string paths to images.
export const images: { [key: string]: string } = {
  Logo: require('./Logo.png'),
  LogoBg: require('./logoBg.png'),
  Chart1: require('./chart.png'),
  Chart2: require('./1.png'),
  Jane: require('./Jane.png'),
  Melisha: require('./Melisha.png'),
  Frank: require('./Frank.png'),
  Mark: require('./Mark.png'),
  noImage: require('./noImage.png'),
  noImageBlack: require('./noImageBlack.png'),
  noImageHorizontal: require('./noImageHorizontal.jpg'),
  placeholder: require('./placeholder.jpg'),   
  imgFacebook: require('./facebook.jpg'),   
  John: require('./John.png'),
  David: require('./David.png'),
  Sandra: require('./Sandra.png'),
  Julia: require('./Julia.png'),
  Dane: require('./Dane.png'),
  GPS: require('./gps.png'),
  Angela: require('./Angela.png'),
  beautySpot: require('./beautySpotRichmond.png'),
  loader: require('./loader.gif'),
  bigJohnDoe: require('./bigJohnDoe.png'),
  candvBeauty: require('./candvBeauty.png'),
  vilette: require('./vilette.png'),
  serenity: require('./serenity.png'),
  davey: require('./davey.png'),
  walton: require('./walton.png'),
  beautyJoane: require('./beautyWithJoane.png'),
  bodyZone: require('./theBodyZone.png'),
  brows: require('./brows.png'),
  video: require('./video.png'),
  anna: require('./anna.png'),
  krasa: require('./krasa.png'),
  arnica: require('./arnica.png'),
  android: require('./android.png'),
  iOS: require('./iOS.png'),
  jack: require('./Jack.png'),
  download: require('./download.png'),
  shopMark: require('./shopMark.png'),
  Franklin: require('./Franklin.png'),
  Olivia: require('./Olivia.png'),
  Klara: require('./Klara.png'),
  Jolly: require('./Jolly.png'),
  Michle: require('./Michle.png'),
  Mihai: require('./Mihai.png'),
  shopAngela: require('./shopAngela.png'),
  addressMap: require('./addressMap.png'),
  shopPhoto1: require('./shopPhoto1.png'),
  shopPhoto2: require('./shopPhoto2.png'),
  shopPhoto3: require('./shopPhoto3.png'),
  shopPhoto4: require('./shopPhoto4.png'),
  customerProfile: require('./customerProfile.png'),
  mobileApp: require('./mobieApp.png'),
  appStore: require('./appStore.png'),
  playStore: require('./playStore.png'),
  adBanner: require('./adBanner.webp'),
  adPerson: require('./adPerson.png'),
  crown: require('./crown.png'),
  maskGroup: require('./mask_group.png'),
  group36: require('./group36.png'),
  group142843: require('./group142843.png'),
  group142842: require('./group142842.png'),
  group142845: require('./group142845.png'),
  group142846: require('./group142846.png'),
  qrcode: require('./qrcode.png'),
  rating: require('./rating.png'),
  group_arrows: require('./group_arrows.png'),
  ellipse12: require('./ellipse12.png'),
  ellipse13: require('./ellipse13.png'),
  location: require('./location.png'),
  purple: require('./Purple.png'),
};
