import React,{ useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Avatar,
} from '@mui/material';
import './salonCard.css';
import { icons } from '../../Assets/icons';
import { images } from '../../Assets/images';
import toast from 'react-hot-toast';
import { ShopAPI } from '../../../src/hooks/shop';
type SalonCardProps = {
  data: any
};

const SalonCard: React.FC<SalonCardProps> = ({
  data
}) => {


  return (
    <Card className="salon-card">
      <Box className="image-section">
        <CardMedia
          component="img"
          height="250"
          image={data.vendor_media.media_name || images.noImageBlack}
          alt={`${data.business_name} salon`}
          className="salon-image"
        />
        <Avatar
          src={icons.redHeart}
          alt="Favorite icon"
          className="favorite-icon"
          sx={{ height: '70px', width: '70px' }}
        />
      </Box>
      <CardContent className="content-section">
        <Box className="salon-info">
          <Typography variant="h6">{data.business_name}</Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className="salon-type"
          >
            {data.gender_type === '1' ? (
        "Male Salon"
      ) : data.gender_type === '2' ? (
        "Female Salon"
      ) : (
        "Unisex Salon"
      )}
          </Typography>
          <Box className="location-section">
            <Avatar
              src={icons.yellowLocation}
              alt="Location icon"
              className="location-icon"
            />
            <Typography variant="body2">{data.vendor_address.address_line_1}</Typography>
          </Box>
        </Box>
        <Box className="rating-section">
          <Typography variant="body2">
            {data.average_review} ({data.total_reviews})
          </Typography>
          <Avatar src={icons.rating} alt="Star rating" className="star-icon" />
        </Box>
      </CardContent>
      {/* <Box className="services-section">
        <Grid container spacing={1}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box className="service-card">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={8} lg={8} key={index}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: {
                          xs: '12px', // small screen
                          sm: '7px', // small to medium screen
                          md: '10px', // medium to large screen
                          lg: '10px', // large screen
                          xl: '11px', // extra large screen
                        },
                        lineHeight: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '10px', // medium to large screen
                          lg: '12px', // large screen
                          xl: '12px', // extra large screen
                        },
                      }}
                      className="service-name"
                    >
                      {service.name}
                    </Typography>

                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{
                        fontSize: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '9px', // medium to large screen
                          lg: '8px', // large screen
                          xl: '11px', // extra large screen
                        },
                        lineHeight: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '11px', // medium to large screen
                          lg: '12px', // large screen
                          xl: '12px', // extra large screen
                        },
                      }}
                      className="service-duration"
                    >
                      {service.duration}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '10px', // medium to large screen
                          lg: '11px', // large screen
                          xl: '11px', // extra large screen
                        },
                        lineHeight: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '6px', // medium to large screen
                          lg: '12px', // large screen
                          xl: '12px', // extra large screen
                        },
                        color: '#0178FA',
                      }}
                      className="service-price"
                    >
                      ₹{service.price}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
          <Button variant="text" className="see-more-btn">
            See More
          </Button>
        </Grid>
      </Box> */}
    </Card>
  );
};

export default SalonCard;
