import React, { useEffect, useState } from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import {
  Container,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  imageListClasses,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './AddressForm.css';
import { icons } from '../../../Assets/icons';
import { images } from '../../../Assets/images';
import { CustomerAddressReqInterface } from '../../../../src/api/types/customer';
import { CommonAPI } from '../../../../src/hooks/common';
import { common } from '@mui/material/colors';
import {
  ApiResponse,
  CityList,
  StateList,
} from '../../../../src/api/types/common';
import { CountryList } from '../../../../src/api/types/common';
import toast from 'react-hot-toast';
import { CustomerAPI } from '../../../../src/hooks/customer';
interface AddressFormProps {
  onCancel: () => void;
  onContinue: () => void;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const AddressForm: React.FC<AddressFormProps> = ({ onCancel, onContinue }) => {
  const { getCountryListAPICall, getStateListAPICall, getCityListAPICall } =
    CommonAPI();
  const { addCustomerAddressAPICall } = CustomerAPI();

  const [value, setValue] = React.useState(0);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [address1, setAddress1] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2, setAddress2] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [state, setState] = useState('');
  const [stateError, setStateError] = useState('');
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState('');
  const [postcode, setPostcode] = useState('');
  const [postcodeError, setPostcodeError] = useState('');

  // List
  const [countryList, setCountryList] = useState<CountryList[]>([]);
  const [stateList, setStateList] = useState<StateList[]>([]);
  const [cityList, setCityList] = useState<CityList[]>([]);

  /* const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setValue(newValue);
  }; */

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };
  function handleContinue() {
    setAddressError('');
    setAddress1Error('');
    setAddress2Error('');
    setPostcodeError('');
    setCityError('');
    setCountryError('');
    setStateError('');
    // let isValid = true;
    if (address === '') {
      setAddressError('Please enter your address');
      // isValid = false;
    }
    if (address1 === '') {
      setAddress1Error('Please enter Address Line 1');
      // isValid = false;
    }
    if (address2 === '') {
      setAddress2Error('Please enter Address Line 2');
      // isValid = false;
    }
    if (city === '') {
      setCityError('Please Select City');
      // isValid = false;
    }
    if (state === '') {
      setStateError('Please Select State');
      // isValid = false;
    }
    if (country === '') {
      setCountryError('Please Select Country');
      // isValid = false;
    }
    if (postcode === '') {
      setPostcodeError('Please enter Address Line 2');
      // isValid = false;
    }

    const req: CustomerAddressReqInterface = {
      // lat: 23.027181141518188,
      // lng: 72.48185665364666,
      address_type: value ? value + 1 : 1,
      address_name: address,
      lattitude: '23.027181141518188',
      longitude: '72.48185665364666',
      address_line_1: address1,
      address_line_2: address2,
      country_id: parseInt(country),
      state_id: parseInt(state),
      city_id: parseInt(city),
      postal_code: parseInt(postcode),
      // "address_id":addressID,
    };
    console.log(req);

    addCustomerAddressAPICallFunc(req);
  }

  // Google Location Search
  const [searchText, setSearchText] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (event: any) => {
    console.log(event, 'event');
    const input = event.target.value;

    setSearchText(input);
    // console.log(input,"input");
    // if (input.length > 2) { // Trigger search only if input is greater than 2 characters
    //   const response = await fetch(
    //     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    //   );
    //   const data = await response.json();
    //   console.log(data,"data");

    //   setSuggestions(data.predictions || []);
    // } else {
    //   setSuggestions([]); // Clear suggestions if input is too short
    // }
  };

  const handleSelect = (suggestion: any) => {
    setSearchText(suggestion.description);
    setSuggestions([]); // Clear suggestions after selection
  };

  console.log(value, 'value');

  console.log(searchText, 'searchText');
  console.log(suggestions, 'suggestions');

  useEffect(() => {
    const countryListData = async () => {
      const result: ApiResponse<CountryList> = await getCountryListAPICall();
      if (result === null) {
        toast.error('Failed to fetch country data.');
        return;
      }
      if (result?.status_code === 200) {
        setCountryList(result.data);
      } else {
        toast.error(result?.message);
      }
    };
    countryListData();
  }, []);

  const getStateListAPICallFunc = async (country_id: number) => {
    const result: ApiResponse<StateList> =
      await getStateListAPICall(country_id);
    if (result === null) {
      toast.error('Failed to fetch state data.');
      return;
    }
    if (result?.status_code === 200) {
      setStateList(result.data);
    } else {
      toast.error(result?.message);
    }
  };

  const getCityListAPICallFunc = async (
    country_id: number,
    state_id: number,
  ) => {
    const result: ApiResponse<CityList> = await getCityListAPICall(
      state_id,
      country_id,
    );
    if (result === null) {
      toast.error('Failed to fetch city data.');
      return;
    }
    if (result?.status_code === 200) {
      setCityList(result.data);
    } else {
      toast.error(result?.message);
    }
  };

  const addCustomerAddressAPICallFunc = async (
    data: CustomerAddressReqInterface,
  ) => {
    const result: any = await addCustomerAddressAPICall(data);
    if (result === null) {
      toast.error('Failed to add customer data.');
      return;
    }
    if (result?.status_code === 200) {
      onCancel();
      toast.success('Address updated successfully');
    } else {
      toast.error(result?.message);
    }
  };

  

  // Define the icon paths
  const homeIconPath =
    'https://cdn.builder.io/api/v1/image/assets/TEMP/9e62b2573251624252a49943d75913f48aa3bcc65c8e04b62e7d62202be41914?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13';
  const workIconPath =
    'https://cdn.builder.io/api/v1/image/assets/TEMP/537f3f8fd5751901aac6090389f63eb2f40a2b65478ada68dff77cbb5b69b17e?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13';
  return (
    <main className="address-form">
      <form className="address-form-content">
        <header className="address-form-header">
          <Typography variant="h4" className="address-form-title">
            New Address
          </Typography>
          <img
            loading="lazy"
            src={icons.cancel}
            alt=""
            className="address-form-image"
            onClick={onCancel}
          />
        </header>
      </form>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs
            value={value}
            className="address-btn"
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* Use img tags to render the icons */}
            <Tab
              icon={
                <img
                src={value === 0 ? icons.homeBlue : icons.home}
                  alt="Home Icon"
                  style={{ width: '24px', height: '24px' }}
                />
              }
              label="Home"
              {...a11yProps(0)}
            />
            <Tab
              icon={
                <img
                src={value === 1 ? icons.workBlue : icons.work}
                  alt="Work Icon"
                  style={{ width: '24px', height: '24px' }}
                />
              }
              label="Work"
              {...a11yProps(1)}
            />
            <Tab
              icon={
                <img
                src={value === 2 ? icons.otherBlue : icons.other}
                  alt="Other Icon"
                  style={{ width: '24px', height: '24px' }}
                />
              }
              label="Other"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className="address-form-fields">
            <TextField
              id="Address-Name"
              variant="outlined"
              placeholder="Address Name"
              InputProps={{ style: { background: 'transparent' } }}
              fullWidth
              error={!!addressError} // Boolean for error prop
              helperText={addressError} // Show error message (optional)
              onChange={e => setAddress(e.target.value)}
            />
            <Box className="address-find">
              <img
                loading="lazy"
                src={workIconPath}
                alt=""
                className="address-find-image"
              />
              <input
                type="text"
                placeholder="Find Address"
                className="address-find-input"
                value={searchText}
                onChange={handleSearch}
              />
              {suggestions.length > 0 && (
                <List>
                  {suggestions.map((suggestion: any) => (
                    <ListItem
                      key={suggestion.place_id}
                      button
                      onClick={() => handleSelect(suggestion)}
                    >
                      <ListItemText primary={suggestion.description} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <TextField
              id="Address-Line-1"
              variant="outlined"
              placeholder="Address Line 1"
              InputProps={{
                style: { background: 'transparent', marginTop: '15px' },
              }}
              fullWidth
              error={!!address1Error} // Boolean for error prop
              helperText={address1Error} // Show error message (optional)
              onChange={e => setAddress1(e.target.value)}
            />
            <TextField
              id="Address-Line-2"
              variant="outlined"
              placeholder="Address Line 2"
              InputProps={{
                style: { background: 'transparent', marginTop: '15px' },
              }}
              fullWidth
              error={!!address2Error} // Boolean for error prop
              helperText={address2Error} // Show error message (optional)
              onChange={e => setAddress2(e.target.value)}
            />
            <Grid
              container
              spacing={2}
              className="address-form-selects"
              sx={{ marginTop: '10px' }}
            >
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!countryError}
                >
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <Select
                    id="country"
                    label="Country"
                    value={country}
                    onChange={e => {
                      setCountry(e.target.value as string);
                      getStateListAPICallFunc(parseInt(e.target.value));
                    }} // Cast event value to string
                  >
                    {countryList.map((countryItem: CountryList) => (
                      <MenuItem
                        key={countryItem.country_id}
                        value={countryItem.country_id}
                      >
                        {countryItem.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {countryError && (
                    <FormHelperText>{countryError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!stateError}>
                  <InputLabel htmlFor="city">State</InputLabel>
                  <Select
                    id="state"
                    label="State"
                    value={state}
                    onChange={e => {
                      setState(e.target.value as string);
                      getCityListAPICallFunc(
                        parseInt(country),
                        parseInt(e.target.value),
                      );
                    }} // Cast event value to string
                  >
                    {stateList.map((object: StateList) => (
                      <MenuItem key={object.id} value={object.id}>
                        {object.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {stateError && <FormHelperText>{stateError}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="address-form-selects"
              sx={{ marginTop: '10px' }}
            >
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!cityError}>
                  <InputLabel htmlFor="city">City</InputLabel>
                  <Select
                    id="city"
                    label="City"
                    value={city}
                    onChange={e => setCity(e.target.value as string)} // Cast event value to string
                  >
                    {cityList.map((object: CityList) => (
                      <MenuItem key={object.id} value={object.id}>
                        {object.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {cityError && <FormHelperText>{cityError}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="Postcode"
                  variant="outlined"
                  placeholder="Postcode"
                  InputProps={{ style: { background: 'transparent' } }}
                  fullWidth
                  error={!!postcodeError} // Boolean for error prop
                  helperText={postcodeError} // Show error message (optional)
                  onChange={e => setPostcode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box className="address-form-buttons">
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleContinue} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="address-form-fields">
            <TextField
              id="Address-Name"
              variant="outlined"
              placeholder="Address Name"
              InputProps={{ style: { background: 'transparent' } }}
              fullWidth
              error={!!addressError} // Boolean for error prop
              helperText={addressError} // Show error message (optional)
              onChange={e => setAddress(e.target.value)}
            />
            <Box className="address-find">
              <img
                loading="lazy"
                src={workIconPath}
                alt=""
                className="address-find-image"
              />
              <input
                type="text"
                placeholder="Find Address"
                className="address-find-input"
                value={searchText}
                onChange={handleSearch}
              />
              {suggestions.length > 0 && (
                <List>
                  {suggestions.map((suggestion: any) => (
                    <ListItem
                      key={suggestion.place_id}
                      button
                      onClick={() => handleSelect(suggestion)}
                    >
                      <ListItemText primary={suggestion.description} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <TextField
              id="Address-Line-1"
              variant="outlined"
              placeholder="Address Line 1"
              InputProps={{
                style: { background: 'transparent', marginTop: '15px' },
              }}
              fullWidth
              error={!!address1Error} // Boolean for error prop
              helperText={address1Error} // Show error message (optional)
              onChange={e => setAddress1(e.target.value)}
            />
            <TextField
              id="Address-Line-2"
              variant="outlined"
              placeholder="Address Line 2"
              InputProps={{
                style: { background: 'transparent', marginTop: '15px' },
              }}
              fullWidth
              error={!!address2Error} // Boolean for error prop
              helperText={address2Error} // Show error message (optional)
              onChange={e => setAddress2(e.target.value)}
            />
            <Grid
              container
              spacing={2}
              className="address-form-selects"
              sx={{ marginTop: '10px' }}
            >
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!countryError}
                >
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <Select
                    id="country"
                    label="Country"
                    value={country}
                    onChange={e => {
                      setCountry(e.target.value as string);
                      getStateListAPICallFunc(parseInt(e.target.value));
                    }} // Cast event value to string
                  >
                    {countryList.map((countryItem: CountryList) => (
                      <MenuItem
                        key={countryItem.country_id}
                        value={countryItem.country_id}
                      >
                        {countryItem.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {countryError && (
                    <FormHelperText>{countryError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!stateError}>
                  <InputLabel htmlFor="city">State</InputLabel>
                  <Select
                    id="state"
                    label="State"
                    value={state}
                    onChange={e => {
                      setState(e.target.value as string);
                      getCityListAPICallFunc(
                        parseInt(country),
                        parseInt(e.target.value),
                      );
                    }} // Cast event value to string
                  >
                    {stateList.map((object: StateList) => (
                      <MenuItem key={object.id} value={object.id}>
                        {object.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {stateError && <FormHelperText>{stateError}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="address-form-selects"
              sx={{ marginTop: '10px' }}
            >
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!cityError}>
                  <InputLabel htmlFor="city">City</InputLabel>
                  <Select
                    id="city"
                    label="City"
                    value={city}
                    onChange={e => setCity(e.target.value as string)} // Cast event value to string
                  >
                    {cityList.map((object: CityList) => (
                      <MenuItem key={object.id} value={object.id}>
                        {object.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {cityError && <FormHelperText>{cityError}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="Postcode"
                  variant="outlined"
                  placeholder="Postcode"
                  InputProps={{ style: { background: 'transparent' } }}
                  fullWidth
                  error={!!postcodeError} // Boolean for error prop
                  helperText={postcodeError} // Show error message (optional)
                  onChange={e => setPostcode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box className="address-form-buttons">
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleContinue} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Box className="address-form-fields">
            <TextField
              id="Address-Name"
              variant="outlined"
              placeholder="Address Name"
              InputProps={{ style: { background: 'transparent' } }}
              fullWidth
              error={!!addressError} // Boolean for error prop
              helperText={addressError} // Show error message (optional)
              onChange={e => setAddress(e.target.value)}
            />
            <Box className="address-find">
              <img
                loading="lazy"
                src={workIconPath}
                alt=""
                className="address-find-image"
              />
              <input
                type="text"
                placeholder="Find Address"
                className="address-find-input"
                value={searchText}
                onChange={handleSearch}
              />
              {suggestions.length > 0 && (
                <List>
                  {suggestions.map((suggestion: any) => (
                    <ListItem
                      key={suggestion.place_id}
                      button
                      onClick={() => handleSelect(suggestion)}
                    >
                      <ListItemText primary={suggestion.description} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <TextField
              id="Address-Line-1"
              variant="outlined"
              placeholder="Address Line 1"
              InputProps={{
                style: { background: 'transparent', marginTop: '15px' },
              }}
              fullWidth
              error={!!address1Error} // Boolean for error prop
              helperText={address1Error} // Show error message (optional)
              onChange={e => setAddress1(e.target.value)}
            />
            <TextField
              id="Address-Line-2"
              variant="outlined"
              placeholder="Address Line 2"
              InputProps={{
                style: { background: 'transparent', marginTop: '15px' },
              }}
              fullWidth
              error={!!address2Error} // Boolean for error prop
              helperText={address2Error} // Show error message (optional)
              onChange={e => setAddress2(e.target.value)}
            />
            <Grid
              container
              spacing={2}
              className="address-form-selects"
              sx={{ marginTop: '10px' }}
            >
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!countryError}
                >
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <Select
                    id="country"
                    label="Country"
                    value={country}
                    onChange={e => {
                      setCountry(e.target.value as string);
                      getStateListAPICallFunc(parseInt(e.target.value));
                    }} // Cast event value to string
                  >
                    {countryList.map((countryItem: CountryList) => (
                      <MenuItem
                        key={countryItem.country_id}
                        value={countryItem.country_id}
                      >
                        {countryItem.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {countryError && (
                    <FormHelperText>{countryError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!stateError}>
                  <InputLabel htmlFor="city">State</InputLabel>
                  <Select
                    id="state"
                    label="State"
                    value={state}
                    onChange={e => {
                      setState(e.target.value as string);
                      getCityListAPICallFunc(
                        parseInt(country),
                        parseInt(e.target.value),
                      );
                    }} // Cast event value to string
                  >
                    {stateList.map((object: StateList) => (
                      <MenuItem key={object.id} value={object.id}>
                        {object.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {stateError && <FormHelperText>{stateError}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="address-form-selects"
              sx={{ marginTop: '10px' }}
            >
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!cityError}>
                  <InputLabel htmlFor="city">City</InputLabel>
                  <Select
                    id="city"
                    label="City"
                    value={city}
                    onChange={e => setCity(e.target.value as string)} // Cast event value to string
                  >
                    {cityList.map((object: CityList) => (
                      <MenuItem key={object.id} value={object.id}>
                        {object.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {cityError && <FormHelperText>{cityError}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="Postcode"
                  variant="outlined"
                  placeholder="Postcode"
                  InputProps={{ style: { background: 'transparent' } }}
                  fullWidth
                  error={!!postcodeError} // Boolean for error prop
                  helperText={postcodeError} // Show error message (optional)
                  onChange={e => setPostcode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box className="address-form-buttons">
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleContinue} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Container maxWidth="lg" className="address-form-container"></Container>
    </main>
  );
};
export default AddressForm;
