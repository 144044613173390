import React from 'react';
import { Card, CardContent, Typography, Box,Grid } from '@mui/material';
import './AppointmentCard.css';
import {useNavigate} from 'react-router-dom';

type AppointmentCardProps = {
  image: string;
  title: string;
  lattitude: string;
  longitude: string;
  date: string;
  price: string;
  itemCount: number;
  isPast?: boolean;
  updateAppointment:Function;
  appointmentData: any;
};

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  image,
  title,
  lattitude,
  longitude,
  date,
  price,
  itemCount,
  isPast = false,
  updateAppointment,
  appointmentData
}) => {




  const navigate = useNavigate();
  function gotoShop(){
    localStorage.setItem('shopID', appointmentData.vendor_detail_id);
    navigate('/shop');
  }
  const handleLocationClick = (lat: any, lng: any, name: any) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&z=15&t=m&label=${encodeURIComponent(name)}`;
    window.open(url, '_blank');
  };
  
  
  
  /* function gotoAppointment(){
    localStorage.setItem('appointmentId',appointmentId);
  } */

  return (
    <Card onClick={() => updateAppointment(appointmentData.id)} className={`appointment-card ${isPast ? 'past' : 'future'}`}>
      <Box className="card-container">
        <Box className="image-container">
          <img
            loading="lazy"
            src={image}
            alt={title}
            className="appointment-image"
          />
        </Box>
        <CardContent className="content-container">
          <Typography variant="h6" className="title">
            {title}
          </Typography>
          <Typography variant="body2" className="date">
            {date}{isPast}
          </Typography>
          <Typography variant="body2" className="price">
            {appointmentData.currency.currency_icon}{price} . {itemCount} Item
          </Typography>
          {isPast ? (
            <Typography onClick={gotoShop} variant="body2" className="book-again">
              Book Again
            </Typography>
          ):(
            <Typography onClick={() => handleLocationClick(lattitude, longitude, title)} variant="body2" className="book-again">
              Get Direction
            </Typography>
          )
          }
        </CardContent>
      </Box>
    </Card>
  );
};
export default AppointmentCard;